
function Size({properties, setSize}) {
  console.log("size:", properties);
  
  return (
    <section className="size">
      <p className="size__text">Выберите размер пазлов (собранный рисунок):</p>
      <div className="size__container">
        {
          properties.sizes.map((size) => (
            <button className="size__item read-more-info-icon modal-right" onClick={() => {setSize(size)}}>
              {size.size}<span className="hidden-info">Размер рисунка: {size.assembledSize}</span>
            </button>
          ))
        }
        
      </div>
    </section>
  );
}

export default Size;