
function Slogan(props) {
  return (
    <section className='history__slogan'>
      <div className='history__about'>
        <p className='history__about-text'>
          {props.pretitle}
        </p>
        <h2 className='history__about-title accent'>{props.title}</h2>
        <p className='history__about-text'>
          {props.slogan}
        </p>
      </div>
      <div className="history__fundamental">
        <p className='history__name-text'>
          {props.text}
        </p>
      </div>
    </section>
  );
}

export default Slogan;

//TODO: Заменить классы (вместо history__about  => slogan__about и тд.), скопировать стили в новые классы и удалить лишние стили в файле index.css
