import ContactForm from "../../ContactForm/ContactForm";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";

function Contacts({ onCallMe, onSubmitContactForm }) {

  return (
    <section className="contacts">
      <Header
        onCallMe={onCallMe}
      />
      <div className="contacts__banner"></div>
      <div className="contacts__container">
        <div className="contacts__address">
          <h2 className="contacts__address-title">Наш офис находится по адресу:</h2>
          <p className="contacts__address-text">г.Москва, улица Адмирала Руднева, 4</p>
        </div>
        <div className="contacts__map">
          <iframe
            title="map"
            src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=57146632690"
            max-width="1160"
            width="100%"
            height="520"
            frameBorder="0"
          >
          </iframe>
        </div>
        <div className="contacts__blocks">
          <div className="contacts__contact-form">
            <ContactForm
              onSubmitForm={onSubmitContactForm}
            />
          </div>
          <div className="contacts__contacts">
            <h2 className="contacts__contacts-title">Вы можете связаться с нами любым удобным способом:</h2>
            <ol className="contacts__contacts-list">
              <li><button className="contacts__list-item">Заполните форму слева и мы вам перезвоним</button></li>
              <li>
                <button className="contacts__list-item">Позвоните по одному из указанных номеров телефона:
                  <ol className="contacts__list-subitem">
                    <li className="contacts__list-item_none">+7 (991) 648-72-91</li>
                    <li className="contacts__list-item_none">+7 (495) 979-79-77</li>
                  </ol>
                </button>
              </li>
              <li><button className="contacts__list-item">Напишите нам на электронную почту по адресу:</button>
                <ol className="contacts__list-subitem">
                  <li className="contacts__list-item_none">info@barkwood.ru</li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default Contacts;