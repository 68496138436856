function Presentation(props) {
  return (
    <section className="presentation">
      <h2 className="presentation__title">{props.title}</h2>
      <div className="presentation__block">
        <div className="presentation__list">
          <p className="presentation__list-item">{props.text1}
          </p>
          <p className="presentation__list-item">{props.text2}
          </p>
          <p className="presentation__list-item">{props.text3}
          </p>
          <p className="presentation__list-item">{props.text4}
          </p>
        </div>
        <img className="presentation__pic" alt='карандашный набросок' src={props.img}></img>
      </div>
    </section>
  );
}

export default Presentation;