import { QuizCardWrapper } from "../../QuizCardWrapper/QuizCardWrapper";

export const Step8 = ({ onClick }) => {
  const data = [
    {
      imageTitle: "Срочно!",
    },
    {
      imageTitle: "В ближайшие 1-3 месяца",
    },
    {
      imageTitle: "После ремонта",
    },
  ];

  const onCardClick = (data) => {
    onClick(data);
  };
  return <QuizCardWrapper lightNext cardData={data} onClick={onCardClick} />;
};