// import AlsoSee from "../../AlsoSee/AlsoSee";
import Banner from "../../Banner/Banner";
import CardList from "../../CardList/CardList";
import Footer from "../../Footer/Footer";

function Furniture({ furniture, children, setCartItemCount, onCallMe }) {
  return (
    <section className="furniture">
      <Banner
        onCallMe={onCallMe}
        title='BARKWOOD'
        subtitle='Изготовление мебели'
        buttonText='В каталог'
        className='banner__furniture'
        to='/catalog'
      />

      <CardList
        cardItems={furniture}
        setCartItemCount={setCartItemCount}
      />
      {/* <AlsoSee cardItems={children} /> */}
      <Footer />
    </section>
  );
}

export default Furniture;