import { useEffect, useRef, useState } from "react";
import { STEPS } from "../constants";
import { Container, Divider, GoBackBtn, Text, Title } from "./styles";
import { StepTransitionWrapper } from "../StepWrapper/StepWrapper";
import cloneDeep from "lodash.clonedeep";
import { quizDataFormatter } from "../helpers";

/**
 * Functional component that renders the QuizWrapper and controls the QuizCards and QuizResults elements.
 * @returns {JSX.Element} - QuizWrapper component.
 */
export const QuizWrapper = () => {
  const [step, setStep] = useState(0);
  const topRef = useRef(null);

  /**
   * State variable that holds the current step's title string.
   */
  const [currentTitle, setCurrentTitle] = useState(STEPS[step].title);
  /**
   * Object that holds the data input by the user on each QuizCard component.
   */
  const [quizData, setQuizData] = useState({
    UF_CRM_QUIZ_1: null,
    UF_CRM_QUIZ_2: null,
    UF_CRM_QUIZ_3: null,
    UF_CRM_QUIZ_4: null,
    UF_CRM_QUIZ_5: null,
    UF_CRM_QUIZ_6: null,
    UF_CRM_QUIZ_7: null,
    UF_CRM_QUIZ_8: null,
    UF_CRM_QUIZ_9: null,
  });
  /**
   * Object representing the current step, holding a component and its corresponding goToNextStep function.
   */
  const [currentComponent, setCurrentComponent] = useState(() => STEPS[step]);

  /**
   * Function that handles the 'Go Back' button click, moves step back by 1.
   * @returns {void}
   */
  const goBack = () => {
    executeScroll();
    setStep(step - 1);
  };
  /**
   * Function that handles the 'Go Next' button click, saves the step's data to quizData and moves step forward by 1.
   * @param {object} stepData - Object representing the current step's input data.
   * @returns {void}
   */
  const goNext = (stepData) => {
    const quizDataLocal = cloneDeep(quizData);
    quizDataLocal[`UF_CRM_QUIZ_${step + 1}`] = quizDataFormatter(
      stepData,
      step,
    );
    setQuizData(quizDataLocal);
    executeScroll();
    setStep(step + 1);
  };
  /**
   * Function that handles scrolling to the current step's title.
   * @returns {void}
   */
  const executeScroll = () => topRef.current.scrollIntoView();

  useEffect(() => {
    setCurrentTitle(STEPS[step].title);
    setCurrentComponent(STEPS[step]);
  }, [step]);

  return (
    <Container>
      <Text ref={topRef}>
        {
          step === 0 ? "Итак, сначала определимся с желаемым стилем и формой, затем решим какие фасады и фурнитура подойдут лучше всего, определимся с желаемой длиной кухонного гарнитура. Когда Вы ответите на эти вопросы, мы сможем рассчитать стоимость кухни, предложим несколько вариантов." : `Шаг ${step + 1} из 10`
        }
        {/* Итак, сначала определимся с желаемым стилем и формой, затем решим какие
        фасады и фурнитура подойдут лучше всего, определимся с желаемой длиной
        кухонного гарнитура. Когда Вы ответите на эти вопросы, мы сможем
        рассчитать стоимость кухни, предложим несколько вариантов. */}
      </Text>
      <Divider />
      <StepTransitionWrapper step={step}>
        <Title>{currentTitle}</Title>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {currentComponent.component({
            goNextFunc: (data) => goNext(data),
            data: quizData,
            setQuizData,
          })}
        </div>

        {step !== 0 && <GoBackBtn onClick={goBack}>Назад</GoBackBtn>}
      </StepTransitionWrapper>
      <Divider />
    </Container>
  );
};