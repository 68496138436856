import styled from "styled-components";

const Card = styled.div`
  min-height: 15vw;
  height: 15vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  color: ${({ light }) => (light ? "#94a389" : "#ffffff")};
  background: ${({ light }) => (light ? "#ffffff" : "#94a389")};
  gap: 4px;
  border-radius: 12px;

  border: 1px solid #94a389;

  transition: all 350ms ease-in-out;
  cursor: pointer;

  :hover {
    filter: brightness(80%);
    transition: all 0.3s ease-in-out;
  }

  @media screen and (max-width: 1290px) {
    min-height: calc(20vw);
  }
  
  @media screen and (max-width: 800px) {
    min-height: calc(22vw);
  }
  
  @media screen and (max-width: 600px) {
    min-height: calc(42vw);
  }
  
  @media screen and (max-width: 400px) {
    min-height: calc(80vw);
  }

`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0;
`;
export const SubTitle = styled.h3`
  font-weight: 400;
  font-size: 0.9rem;
  margin: 0;
`;

export const NextStepElement = ({ light, onClick }) => {
  return (
    <Card light={light} onClick={() => onClick()}>
      <Title>Свой вариант</Title>
      <SubTitle>обсудим с менеджером</SubTitle>
    </Card>
  );
};
