import { QuizCardWrapper } from "../../QuizCardWrapper/QuizCardWrapper";
import Img1 from "../../../../../images/quiz_img/HeightKitchen1.webp";
import Img2 from "../../../../../images/quiz_img/HeightKitchen2.webp";
import Img3 from "../../../../../images/quiz_img/HeightKitchen3.webp";
import Img4 from "../../../../../images/quiz_img/HeightKitchen4.webp";

export const Step5 = ({ onClick }) => {
  const data = [
    {
      image: Img1,
      title: "Без верхних модулей",
    },
    {
      image: Img2,
      title: "Антресоли средней высоты",
    },
    {
      image: Img3,
      title: "До потолка (самое эстетичное)",
    },
    {
      image: Img4,
      title: "До потолка с глубокими антресолями",
    },
  ];

  const onCardClick = (data) => {
    onClick(data);
  };
  return (
    <QuizCardWrapper cardData={data} onClick={onCardClick}></QuizCardWrapper>
  );
};
