import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import CartItem from "../../CartItem/CartItem";
import cart from "../../../utils/CartCore";
import cache from "../../../utils/ItemCache";
import { useContext, useEffect, useState } from "react";
import { CartTotalCountContext } from "../../../contexts/CartTotalCountContext";
import ContactForm from "../../ContactForm/ContactForm";

function Cart({ setCartItemCount, onCallMe, onSubmitCartForm}) {
  const [itemsToShow, setItemsToShow] = useState([]);
  const [totalCartItemCount, setTotalCartItemCount] = useContext(CartTotalCountContext);
  const [totalSum, setTotalSum] = useState(0);

  const updateCartItemCount = (productId, sizeId, newCount, priceDiff) => {
    setCartItemCount(productId, sizeId, newCount);
    setTotalSum(sum => sum + priceDiff);
  }

  useEffect(() => {
    console.log('useEffect');
    setItemsToShow((itemsToShow) => []);
    setTotalSum(0);
    cart.getCurrentItems().forEach((itemInCart, productId) => {
      console.log(`id: ${productId}, itemInCart: ${itemInCart}`);

      itemInCart.forEach((count, sizeId) => {
        console.log(`sizeId: ${sizeId}, count: ${count}`);

        cache.getCardInfo(productId)
          .then((info) => {

            let sizeData;

            info.sizes.forEach((size) => {
              if (size._id === sizeId) {
                sizeData = size;
              }
            })
            //sizeData.productId = productId;

            setItemsToShow((itemsToShow) => [
              ...itemsToShow,
              {
                count,
                cardInfo: sizeData,
                productId,
                name: info.name
              }
            ]);
            setTotalSum(sum => sum + sizeData.price * count);
          })
          .catch(err => console.log(err));
      });
    });
    // eslint-disable-next-line
  }, [])

  const removeItem = (productId, sizeId) => {
    setItemsToShow(items => {
      console.log("items before update");
      console.log(items);
      const newItems = items.filter(item => item.productId !== productId || item.cardInfo._id !== sizeId)
      console.log("items after update");
      console.log(newItems);
      return newItems;
    });
  }

  console.log(itemsToShow);

  const onSubmitForm = (values) => {
    return onSubmitCartForm({
      name: values.name,
      phone: values.phone,
      email: values.email,
      items: itemsToShow.map(item => {
        return {
          productId: item.productId,
          sizeId: item.cardInfo._id,
          count: item.count,
          name: item.name,
          price: item.cardInfo.price,
          promo: item.cardInfo.promo,
          promoPrice: item.cardInfo.promoPrice,
          size: item.cardInfo.size
        };
      })
    });
  }

  return (
    <section className="cart">
      <Header
        onCallMe={onCallMe}
      />
      <div className="cart__banner"></div>
      <h2 className="cart__title">Корзина</h2>
      <div className="cart__items">
        {
          itemsToShow.map((cartItem) => (
            <CartItem
              key={cartItem.cardInfo._id}
              properties={cartItem}
              updateCartItemCount={updateCartItemCount}
              removeItem={removeItem}
            />))
        }
        <div className="cart__total">
          <div className="cart__total-pic"></div>
          <p className="cart__total-text">ИТОГО:</p>
          <p className="cart__total-count">{totalCartItemCount}</p>
          <p className="cart__total_sum">{totalSum.toLocaleString()}</p>
          <div className="cart__total-delete"></div>
        </div>
      </div>
      <div className="cart__contact-form">
        <ContactForm
          onSubmitForm={onSubmitForm}
        // data={itemsToShow}
        />
        <div className="cart__order">
          <h2 className="cart__order-title">Как оформить заказ:</h2>
          <ol className="cart__order-list">
            <li>
              <p className="cart__list-item">Выберите нужные товары и их количество</p>
            </li>
            <li>
              <p className="cart__list-item">Заполните форму и нажмите кнопку "Отправить"</p>
            </li>
            <li>
              <p className="cart__list-item">Заварите чай. Наши менеджеры свяжутся с вами в ближайшее время</p>
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default Cart;