import Hovered from "../Hovered/Hovered";
import Header from "../Header/Header";
import Video from "../../images/video-button.svg"
import { Link } from "react-router-dom";


function Banner(props) {

  return (
    <section className={`banner ${props.className}`}>
      <Header
        onCallMe={props.onCallMe}
      />
      <div className="banner__container">
        <div className='banner__text'>
          <h1 className="banner__title">
            {props.title}
          </h1>
          <h2 className="banner__subtitle">
            {props.subtitle}
          </h2>
          <Hovered>
            <Link to={props.to}>
              <button className='banner__button'>{props.buttonText}</button>
            </Link>
          </Hovered>
        </div>
        <div className="banner__video">
          <img className="banner__video-button" alt="Кнопка проигрывания видео" src={Video}></img>
          <p className="banner__video-text">О нас за 2 минуты</p>
        </div>
      </div>
    </section>
  );
}

export default Banner;