// import AlsoSee from "../../AlsoSee/AlsoSee";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import Banner from "../../Banner/Banner";
import CardList from "../../CardList/CardList";

function Children({ children, relatedProducts, setCartItemCount, onCallMe }) {
  return (
    <section className="children">
      <Banner
        onCallMe={onCallMe}
        title='Делаем своими руками'
        subtitle='изделия из ценных пород дерева'
        buttonText='Вернуться в каталог'
        className='banner__children'
        to='/catalog'
        />
      <CardList
        cardItems={children}
        setCartItemCount={setCartItemCount}
      />
      {/* <AlsoSee cardItems={relatedProducts} /> */}
      <Footer />
    </section>
  );
}

export default Children;