import React from "react";

function Hovered({
  children
}) {

  const HoveredChildren = () =>
    React.Children.map(children, child =>
      React.cloneElement(child, {
        className: `${child.props.className} hovered`
      })
    );

  return <HoveredChildren />;
};

export default Hovered;