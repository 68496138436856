import ya_icon from '../images/review_icons/icon_ya.svg';
import ya_icon_dis from '../images/review_icons/icon_ya_dis.svg';
import wb_icon from '../images/review_icons/icon_wb.svg';
import wb_icon_dis from '../images/review_icons/icon_wb_dis.svg';
import oz_icon from '../images/review_icons/icon_oz.svg';
import oz_icon_dis from '../images/review_icons/icon_oz_dis.svg';
import vk_icon from '../images/review_icons/icon_vk.svg';
import vk_icon_dis from '../images/review_icons/icon_vk_dis.svg';
import ok_icon from '../images/review_icons/icon_ok.svg';
import ok_icon_dis from '../images/review_icons/icon_ok_dis.svg';
import sm_icon from '../images/review_icons/icon_sm.svg';
import sm_icon_dis from '../images/review_icons/icon_sm_dis.svg';


const ReviewData = [
  {
    id: 1,
    link_name: "Яндекс карты",
    link_url: "https://yandex.ru/maps/org/barkwood/191015704938/reviews",
    img_src: ya_icon,
    disabled_img_src: ya_icon_dis,
    disabled: false,
  },
  {
    id: 2,
    link_name: "Вайлдберриз",
    link_url: "https://www.wildberries.ru/catalog/163926845/feedbacks",
    img_src: wb_icon,
    disabled_img_src: wb_icon_dis,
    disabled: false,
  },
  {
    id: 3,
    link_name: "Озон",
    link_url: "https://www.ozon.ru/product/1012995693/reviews",
    img_src: oz_icon,
    disabled_img_src: oz_icon_dis,
    disabled: false,
  },
  {
    id: 4,
    link_name: "Вконтакте",
    link_url: "https://vk.com/topic-221278819_49357927",
    img_src: vk_icon,
    disabled_img_src: vk_icon_dis,
    disabled: false,
  },
  // {
  //   id: 5,
  //   link_name: "Одноклассники",
  //   link_url: "https://ok.ru/group/70000003371761",
  //   img_src: ok_icon,
  //   disabled_img_src: ok_icon_dis,
  //   disabled: true,
  // },
  // {
  //   id: 6,
  //   link_name: "Сбер маркет",
  //   link_url: "#",
  //   img_src: sm_icon,
  //   disabled_img_src: sm_icon_dis,
  //   disabled: true,
  // },
]

export default ReviewData;