// import Chairs from "../../../images/catalog-chairs.png";
// import Puzzles from "../../../images/catalog-puzzle.png";
// import Pillows from "../../../images/catalog-pillow.png";
// import CuttingBoards from "../../../images/catalog-cutting-board.png";

import { Link } from "react-router-dom";
import Hovered from "../../../Hovered/Hovered";


function CatalogClasses() {
  return (
    <section className="catalog-classes">
      <h2 className="catalog-classes__title">
        Каталог
      </h2>
      <div className="catalog-classes__items">
        <Hovered>
          <Link to='/kitchens' className="catalog-classes__item catalog-classes__kitchens">
            <h2 className="catalog-classes__item-title">BARKWOOD</h2>
            <h3 className="catalog-classes__item-subtitle">КУХНИ</h3>
          </Link>  
        </Hovered>
        <Hovered>
          <Link to='/puzzles' className="catalog-classes__item catalog-classes__puzzles">
            <h2 className="catalog-classes__item-title">BARKWOOD</h2>
            <h3 className="catalog-classes__item-subtitle">ПАЗЛЫ</h3>
          </Link>
        </Hovered>
        <Hovered>
          <Link to='/furniture' className="catalog-classes__item catalog-classes__furniture">
            <h2 className="catalog-classes__item-title">BARKWOOD</h2>
            <h3 className="catalog-classes__item-subtitle">МЕБЕЛЬ</h3>
          </Link>
        </Hovered>
        <Hovered>
          <Link to='/children' className="catalog-classes__item catalog-classes__boards">
            <h2 className="catalog-classes__item-title">BARKWOOD</h2>
            <h3 className="catalog-classes__item-subtitle">ДЕТЯМ</h3>
          </Link>
        </Hovered>
        <Hovered>
          <Link to='/houses' className="catalog-classes__item catalog-classes__houses">
            <h2 className="catalog-classes__item-title">BARKWOOD</h2>
            <h3 className="catalog-classes__item-subtitle">ДОМА</h3>
          </Link>
        </Hovered>
        <Hovered>
          <Link to='/decor' className="catalog-classes__item catalog-classes__decor">
            <h2 className="catalog-classes__item-title">BARKWOOD</h2>
            <h3 className="catalog-classes__item-subtitle">ДЕКОР</h3>
          </Link>
        </Hovered>
      </div>
    </section>
  );
}

export default CatalogClasses;

//TODO Переименовать доски в детей