function FurnitureText() {
  return (
    <section className="furniture-text">
      <div className="card-info__text-container">
        <h3 className="card-info__text-title">Современная мебель: идеальное сочетание эстетики и функциональности.</h3>
        <p className="card-info__text-description">
          В современном мире, когда дизайн и функциональность играют важную роль в нашей жизни, выбор мебели становится 
          вопросом особой важности. Наша компания предлагает вам уникальную возможность обустроить ваш дом с помощью 
          изготовленной на заказ мебели из МДФ и ЛДСП. Мы специализируемся на создании классического, минималистичного и 
          футуристичного дизайна, которые сочетают в себе эстетику и функциональность, отражая современные тенденции и инновационные решения. 
        </p>
        <p className="card-info__text-description">
          Мы используем только высококачественные материалы при производстве мебели. МДФ и ЛДСП являются прочными и надежными материалами, 
          которые обладают длительным сроком службы. Благодаря использованию таких материалов, наша мебель легка в уходе и проста в эксплуатации, 
          что позволяет вам наслаждаться ее красотой и функциональностью на протяжении долгих лет.
        </p>
        <p className="card-info__text-description">
          Минимализм в дизайне. Минимализм - это не только стиль, но и философия, которая подразумевает упрощение и устранение излишеств. 
          Мы стремимся создавать мебель, которая отражает суть минимализма, обладает простыми формами и чистыми линиями. 
          Такой дизайн придает вашему интерьеру элегантность и гармонию, создавая ощущение пространства и света.
        </p>
        <p className="card-info__text-description">
          Футуристический взгляд на дизайн. Наша компания также специализируется на футуристическом дизайне, который представляет собой 
          сочетание современных технологий и инновационных решений. Мы стремимся создавать мебель, которая не только функциональна, 
          но и внешне привлекательна. Футуристические элементы, такие как гладкие поверхности, необычные формы и использование 
          светодиодной подсветки, делают нашу мебель уникальной для тех, кто стремится к современному облику своего дома. 
        </p>
      </div>
    </section>
  );
}

export default FurnitureText;
