import { Link } from "react-router-dom";
import Hovered from "../../../Hovered/Hovered";

function More({handleViewOnTop}) {

  return (
    <section className="more">
      <h2 className="more__title">Узнайте больше</h2>
      <div className="more__items">
        <Hovered>
          <Link to={'/history'} className="more__item more__about">
            <button className='more__item-title'  onClick={handleViewOnTop}>О нашей мастерской</button>
          </Link>
        </Hovered>
        <Hovered>
          <Link to={'/for-buyers'} className="more__item more__delivery">
            <button className='more__item-title' onClick={handleViewOnTop}>Доставка и оплата</button>
          </Link>
        </Hovered>
        <Hovered>
          <div className="more__item more__creation">
            <button className='more__item-title'  onClick={handleViewOnTop}>Как создается изделие</button>
          </div>
        </Hovered>
      </div>
    </section>
  );
}

export default More;