import { Button, ShadowButton } from "./styles";

export const ButtonComponent = ({
  type,
  styles,
  rounded,
  onBtnClick,
  children,
  disabled = false,
}) => {
  return (
    <>
      {!type && (
        <Button
          disabled={disabled}
          styles={styles}
          rounded={rounded}
          onClick={(e) => onBtnClick(e)}
        >
          {children}
        </Button>
      )}
      {type === "shadow" && (
        <ShadowButton
          disabled={disabled}
          styles={styles}
          rounded={rounded}
          onClick={(e) => onBtnClick(e)}
        >
          {children}
        </ShadowButton>
      )}
    </>
  );
};