import { palette } from "../palette";
import styled from "styled-components";

const Text = styled.p`
  font-size: 0.9rem;
  line-height: 1.1rem;
  text-align: right;
  margin: 0;
  color: white;

  text-shadow: 1px 1px 1px rgba(29, 29, 29, 0.3);
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  text-align: right;
  padding-top: 20px;

  color: ${palette.colors.txt_gray};
`;
const ContentDefault = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;
  text-align: right;
  padding-top: 200px;

  color: ${'#fff'};
`;
const Title = styled.h1`
  font-weight: 400;
  font-size: 4.5rem;
  line-height: 5.5rem;
  margin: 0;
  color: white;
  text-shadow: 1px 1px 1px rgba(29, 29, 29, 0.3);
`;

const Title2 = styled.h5`
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  max-width: 600px;
  text-align: left;
  margin: 0;
  text-shadow: 1px 1px 1px rgba(29, 29, 29, 0.3);
`;
const SubTitle = styled.h1`
  color: var(--secondary-accent-color);
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  margin: 0;
  max-width: 500px;
  text-shadow: 1px 1px var(--text-shadow-color);
`;

export const BannerContentMain = () => {
  return (
    <Content>
      <Text>
        Мы подчёркиваем природную красоту форм <br />
        и показываем ее под нужным углом. Так, предметы <br />
        интерьера взаимодействуют с пространством <br />и наполняют своей
        энергетикой каждого
      </Text>
      <Title>BARKWOOD</Title>
      <Text>Оптимальные решения для комфорта и уюта в Вашем доме</Text>
    </Content>
  );
};

export const BannerContentQuiz = () => {
  return (
    <ContentDefault>
      <Title2>BARKWOOD</Title2>
      <SubTitle>КУХНИ</SubTitle>
      <Text>Оптимальные решения для комфорта и уюта в Вашем доме</Text>
    </ContentDefault>
  );
};
export const BannerContentPolitics = () => {
  return (
    <ContentDefault>
      <Title2>BARKWOOD</Title2>
      <SubTitle
        style={{
          fontSize: "3rem",
          lineHeight: "3rem",
          margin: "1rem 0",
          fontFamily: "Commissioner",
        }}
      >
        Политика в отношении обработки
      </SubTitle>
      <Text>персональных данных</Text>
    </ContentDefault>
  );
};
export const BannerContentGallery = () => {
  return (
    <ContentDefault>
      <Title2>BARKWOOD</Title2>
      <SubTitle>ГАЛЕРЕЯ</SubTitle>
      <Text>Оптимальные решения для комфорта и уюта в Вашем доме</Text>
    </ContentDefault>
  );
};
