import Banner from "../../Banner/Banner";
import Footer from "../../Footer/Footer";
import Collage from "../../Collage/Collage";
import Services from "../../Services/Services";

function LowCurrent ({onCallMe}) {
  return (
      <section className="low-current">
        <Banner
          onCallMe={onCallMe}
          title='BARKWOOD'
          subtitle='Слаботочные системы'
          buttonText='Заказать расчет'
          className='banner__low-current'
        />
        <h2 className="low-current__title">Слаботочные системы "под ключ":</h2>
        <Collage
          paragraph1='Видеонаблюдение позволяет обеспечить безопасность и контроль внутри и вокруг здания, предотвратить проникновение злоумышленников и зафиксировать любые подозрительные действия.'
          paragraph2='Однако, видеонаблюдение - это лишь одна из составляющих системы безопасности. Для полноценной защиты объекта необходимо установить и другие охранные системы, такие как системы охранной сигнализации и пожарно-охранной системы.'
          paragraph3='Помимо систем безопасности, важным компонентом обеспечения безопасности объекта является система контроля и управления доступом (СКУД). Она позволяет регулировать доступ к определенным зонам или помещениям, устанавливать ограничения на время пребывания и контролировать перемещение людей внутри объекта. '
          paragraph4='СКУД может быть оснащена различными считывающими устройствами, такими как карты доступа, биометрические сенсоры или кодовые замки.'
          pic1='collage__low-current-pic1'
          pic2='collage__low-current-pic2'
          pic3='collage__low-current-pic3'
          pic4='collage__low-current-pic4'
          pic5='collage__low-current-pic5'
        />
        <Services 
          title='Монтаж "под ключ":'
          stage1='Составление проекта (схемы)'
          stage2='Закупка материалов и оборудования'
          stage3='Монтаж домофонной системы'
          stage4='Монтаж систем оповещения и музыкальной трансляции'
          stage5='Монтаж систем контроля и управления доступом'
          stage6='Монтаж локальной сети'
          stage7='Монтаж cистем видеонаблюдения'
          stage8='Монтаж охранной GSM сигнализации'
          stage9='Монтаж системы пожарной сигнализации'
          stage10='Монтаж системы пожаротушения (газового, порошкового)'

          />
        <Footer />
      </section>
  );
}

export default LowCurrent;