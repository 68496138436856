
function GalleryBlock({ properties }) {

  if (!properties) {
    return <></>
  }
  return (
    <section className="gallery-block">
      <div className="gallery-block__image-container">
        <div className="gallery-block__title-pic">
          <img className='gallery-block__images' src={properties.images[0]} alt="изображение объекта"></img>
          <div className="gallery-block__title-overlay">
            <p className="gallery__object-name">{properties.name}</p>
            <p className="gallery__object-fulfillment">Срок изготовления: {properties.days} дней.</p>
            <p className="gallery__object-fulfillment">Стоимость: {properties.price.toLocaleString()} руб.</p>
          </div>
        </div>
        <div className="gallery-block__pic2">
          <img className='gallery-block__images' src={properties.images[0]} alt="изображение объекта"></img>
        </div>
        <div className="gallery-block__pic4">
          <img className='gallery-block__images'src={properties.images[1]} alt="изображение объекта"></img>
        </div>
        <div className="gallery-block__pic3">
          <img className='gallery-block__images' src={properties.images[2]} alt="изображение объекта"></img>
        </div>
        <div className="gallery-block__pic5">
          <img className='gallery-block__images' src={properties.images[3]} alt="изображение объекта"></img>
        </div>
        <div className="gallery-block__pic6">
        <img  className='gallery-block__images'src={properties.images[4]} alt="изображение объекта"></img>
        </div>
        <div className="gallery-block__pic7">
          <img  className='gallery-block__images'src={properties.images[5]} alt="изображение объекта"></img>
        </div>
        <div className="gallery-block__pic8">
          <img className='gallery-block__images' src={properties.images[6]} alt="изображение объекта"></img>
        </div>
        <div className="gallery-block__pic9">
          <img className='gallery-block__images' src={properties.images[7]} alt="изображение объекта"></img>
        </div>
      </div>
    </section>
  )
}

export default GalleryBlock;

<div class="container">
  
</div>