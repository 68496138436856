import { QuizCardWrapper } from "../../QuizCardWrapper/QuizCardWrapper";

import Image1 from "../../../../../images/quiz_img/quiz_4_miling_true.webp";
import Image2 from "../../../../../images/quiz_img/quiz_4_miling_false.webp";

export const Step3 = ({ onClick }) => {
  const data = [
    {
      image: Image1,
      title: "С фрезеровкой",
    },
    {
      image: Image2,
      title: "Без фрезеровки",
    },
  ];

  const onCardClick = (data) => {
    onClick(data);
  };

  return <QuizCardWrapper count="3" cardData={data} onClick={onCardClick} />;
};
