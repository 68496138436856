import Hovered from "../Hovered/Hovered";
import { HashLink as Link } from "react-router-hash-link";
import Logo from "../Logo/Logo";
import Cart from "../../images/shopping-cart.svg";
import { useContext } from "react";
import { CartTotalCountContext } from "../../contexts/CartTotalCountContext";

function Navigation({ onCallMe, isBurgerMenuOpened, handleBurgerClose }) {

  const [totalCartItemCount, setTotalCartItemCount] = useContext(CartTotalCountContext);

  const handleBackgroundClick = (e) => {
    if (e.target.className === 'navigation__background') {
      handleBurgerClose();
    }
  }

  return (
    <nav id="nav" className={`navigation${isBurgerMenuOpened ? " navigation_burger-opened" : ""}`}>
      <div className='navigation__background' onClick={handleBackgroundClick}>
        <Hovered>
          <button className='navigation__close-button' onClick={handleBurgerClose}></button>
        </Hovered>
        <div className='navigation__container' onClick={handleBackgroundClick}>
          <Hovered>
            <Link to="/catalog" className="navigation__item" onClick={handleBurgerClose}>
              Каталог
            </Link>
          </Hovered>
          <Hovered>
            <Link to="/history" className="navigation__item" onClick={handleBurgerClose}>
              О нас
            </Link>
          </Hovered>
          <Hovered>
            <Link to="/gallery" className="navigation__item" onClick={handleBurgerClose}>
              Галерея
            </Link>
          </Hovered>
          <Hovered>
            <Link to='/' className="navigation__item" onClick={handleBurgerClose}>
              <Logo />
            </Link>
          </Hovered>
          <Hovered>
            <Link to="/contacts" className="navigation__item" onClick={handleBurgerClose}>
              Контакты
            </Link>
          </Hovered>
          <div className="navigation__call">
            <Hovered>
              <p className="navigation__phone">+7 (495) 97 97 97 7</p>
              <button className="navigation__item" onClick={onCallMe}>
                Заказать звонок
              </button>
            </Hovered>
          </div>

          <Hovered>
            <Link to="/cart" className="navigation__item">
              <img alt="Корзина" src={Cart}></img>
              <div className="navigation__cart-counter">
                <p className="navigation__cart-number">{totalCartItemCount}</p>
              </div>
            </Link>
          </Hovered>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;