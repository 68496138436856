import { API_URLS } from "./constants";
const CURRENT_URL = API_URLS.BASE_URL;

const checkResponse = (res) => {
  if (res.ok) {
    return res.json();
  }
  return res.json().then((data) => {
    return Promise.reject({
      message: `Получена ошибка, код: ${res.status}, описание: ${data.message}`,
      status: res.status,
    });
  });
};

const headers = {
  "Content-Type": "application/json",
};

// получить продукт по ID -------------------------------------------
const getProductById = async (id) => {
  const res = await fetch(`${CURRENT_URL}/products/${id}`, {
    method: "GET",
    headers,
  });
  return checkResponse(res);
}


// получить список пазлов -----------------------------------------
const getPuzzles = async () => {
  const res = await fetch(`${CURRENT_URL}/products/puzzles`, {
    method: "GET",
    headers,
  });
  return checkResponse(res);
};

// получить список мебели -----------------------------------------
const getFurniture = async () => {
  const res = await fetch(`${CURRENT_URL}/products/furniture`, {
    method: "GET",
    headers,
  });
  return checkResponse(res);
};

// получить список детского -----------------------------------------
const getChairsData = async () => {
  const res = await fetch(`${CURRENT_URL}/products/chairs`, {
    method: "GET",
    headers,
  });
  return checkResponse(res);
};

// получить список детских подушек -----------------------------------------
const getPilowsData = async () => {
  const res = await fetch(`${CURRENT_URL}/products/pillows`, {
    method: "GET",
    headers,
  });
  return checkResponse(res);
};

// получить список детских стульев-----------------------------------------
const getKids = async () => {
  const res = await fetch(`${CURRENT_URL}/products/kids`, {
    method: "GET",
    headers,
  });
  return checkResponse(res);
};

// получить список детского -----------------------------------------
const getDecor = async () => {
  const res = await fetch(`${CURRENT_URL}/products/decor`, {
    method: "GET",
    headers,
  });
  return checkResponse(res);
};

// получить список галереи ------------------------------------------
const getGallery = async () => {
  const res = await fetch(`${CURRENT_URL}/gallery`, {
    method: "GET",
    headers,
  });

  return checkResponse(res);
};

// отправить данные с формы "связаться с нами" ------------------------------------------
const postFeedback = async (data) => {
  const res = await fetch(`${CURRENT_URL}/tg`, {
    method: "POST",
    headers,
    body: JSON.stringify({
      name: data.name,
      phone: data.phone,
      email: data.email,
    }),
  });

  return checkResponse(res);
};

// // отправить данные с формы "заказать звонок" ------------------------------------------
// const postRecall = async (data) => {
//   const res = await fetch(`${BACK_URL}/tg`, {
//     method: "POST",
//     headers,
//     body: JSON.stringify({
//       name: data.name,
//       phone: data.phone
//     }),
//   });

//   return checkResponse(res);
// };

// отправить "заказ из корзины на в телеграм" ------------------------------------------
const postPurchase = async (data) => {
  console.log("вот, что с корзиной отправляется");
  console.log(data);
  const res = await fetch(`${CURRENT_URL}/tg/ps`, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });

  return checkResponse(res);
};

// отправить заполненный квиз в Битрикс
const postBxDeal = async (data) => {
  const res = await fetch(`${CURRENT_URL}/bitrixApi`, {
    method: "POST",
    headers,
    body: JSON.stringify({
      name: data.name,
      phone: data.phone,
      quizData: data.quizData
    }),
  });

  return checkResponse(res);
};


export {
  getPuzzles,
  getFurniture,
  getKids,
  getDecor,
  getProductById,
  getGallery,
  getChairsData,
  getPilowsData,
  postFeedback,
  postBxDeal,
  postPurchase,
}