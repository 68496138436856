import { QuizCardWrapper } from "../../QuizCardWrapper/QuizCardWrapper";

export const Step6 = ({ onClick }) => {
  const data = [
    {
      imageTitle: "Эконом",
      title: "",
    },
    {
      imageTitle: "Стандарт",
      title: "",
    },
    {
      imageTitle: "Премиум",
      title: "",
    },
  ];

  const onCardClick = (data) => {
    onClick(data);
  };
  return (
    <QuizCardWrapper
      lightNext
      cardData={data}
      onClick={onCardClick}
    ></QuizCardWrapper>
  );
};