
function Formula() {
  return (
    <section className="formula">
      <h2 className="formula__title"><span className="accent">BARKWOOD</span> — это красота, продуманность и экологичность</h2>
      <div className="formula__items">
        <div className="formula__item formula__photo1">
        </div>
        <div className="formula__item formula__photo2">
        </div>
        <div className="formula__item formula__photo3">
        </div>
        <div className="formula__item formula__photo4">
        </div>
      </div>
    </section>
  );
}

export default Formula;