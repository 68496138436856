import { Link } from "react-router-dom";
import { palette } from "../palette";
import styled from "styled-components";

export const FormTitle = styled.h1`
  text-align: end;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.3rem;
  margin: 0;
  color: ${palette.colors.txt_gray};
`;
export const FieldSet = styled.fieldset`
  display: flex;
  flex-direction: column;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  gap: 1.3rem;
`;

export const FormControl = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.8rem;
  line-height: 0.9rem;
  padding: 2rem 0;
  color: ${palette.colors.txt_gray};
`;

export const Label = styled.label`
  cursor: pointer;
`;

export const TextLink = styled(Link)`
  text-decoration: underline;
  color: ${palette.colors.txt_gray};
`;
