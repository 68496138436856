import { useEffect } from "react";

function FilterButton({ title, handleClick }) {

  const changeFilters = () => {
    handleClick(title);
  };

  return (
    <section className="filter-button">
      <button className="filter-button__item" onClick={changeFilters}>{title}</button>
    </section>
  );
}

export default FilterButton;