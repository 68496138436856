import { StepO } from "./QuizComponents/Step0/Step0";
import { Step1 } from "./QuizComponents/Step1/Step1";
import { Step2 } from "./QuizComponents/Step2/Step2";
import { Step3 } from "./QuizComponents/Step3/Step3";
import { Step4 } from "./QuizComponents/Step4/Step4";
import { Step5 } from "./QuizComponents/Step5/Step5";
import { Step6 } from "./QuizComponents/Step6/Step6";
import { Step7 } from "./QuizComponents/Step7/Step7";
import { Step8 } from "./QuizComponents/Step8/Step8";
import { Step9 } from "./QuizComponents/Step9/Step9";

export const STEPS = [
  {
    title: "Выберите стиль кухни. Цвет не важен, выбирайте именно стиль",
    step: 0,
    component: ({ goNextFunc }) => (
      <StepO onClick={(data) => goNextFunc(data)} />
    ),
  },
  {
    title: "Теперь выберите форму вашей кухни",
    step: 1,
    component: ({ goNextFunc }) => (
      <Step1 onClick={(data) => goNextFunc(data)} />
    ),
  },
  {
    title: "Выберите покрытие фасадов",
    step: 2,
    component: ({ goNextFunc }) => (
      <Step2 onClick={(data) => goNextFunc(data)} />
    ),
  },
  {
    title: "Выберите вид фасадов",
    step: 3,
    component: ({ goNextFunc }) => (
      <Step3 onClick={(data) => goNextFunc(data)} />
    ),
  },
  {
    title: "Укажите примерную длину кухонного гарнитура",
    step: 4,
    component: ({ goNextFunc }) => (
      <Step4 onClick={(data) => goNextFunc(data)} />
    ),
  },
  {
    title: "Выберите высоту верхних модулей",
    step: 5,
    component: ({ goNextFunc }) => (
      <Step5 onClick={(data) => goNextFunc(data)} />
    ),
  },
  {
    title: "Какую фурнитуру считать? (петли, ящики, ручки т.п.)",
    step: 6,
    component: ({ goNextFunc }) => (
      <Step6 onClick={(data) => goNextFunc(data)} />
    ),
  },
  {
    title: "Выберите встроенную технику",
    step: 7,
    component: ({ goNextFunc }) => (
      <Step7 onClick={(data) => goNextFunc(data)} />
    ),
  },
  {
    title: "Когда надо чтобы кухня стояла у вас дома? (это влияет на цену)!",
    step: 8,
    component: ({ goNextFunc }) => (
      <Step8 onClick={(data) => goNextFunc(data)} />
    ),
  },
  {
    title: "Последний шаг для получения предложения!",
    step: 9,
    component: ({ data, setQuizData }) => (
      <Step9 quizData={data} setQuizData={setQuizData} />
    ),
  },
];
