import { NextStepElement } from "../NextStepElement/NextStepElement";
import { QuizCard } from "../QuizCard/QuizCard";
import styled from "styled-components";

const QuizCardWrapperContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.count}, 15vw)`};

  gap: 1.3rem 2rem;
  padding: 3rem 6rem;

  transition: all 350ms ease-in-out;

  @media screen and (max-width: 1290px) {
    grid-template-columns: repeat(3, 22vw)
  }
  
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(3, 28vw)
  }
  
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 40vw)
  }
  
  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(1, 90vw)
  }
  `;

/**
 * Functional component that wraps and maps QuizCard components to display them in a grid layout.
 * @param {function} onClick - Click handler function to be passed down to child components.
 * @param {array} cardData - Array of objects representing data for quiz cards.
 * @param {number} count - how much column
 * @param {boolean} lightNext - Optional flag indicating whether the next step element should appear as light. Default value is false.
 * @returns {JSX.Element} - QuizCardWrapperContainer component containing the mapped array of QuizCard components and a NextStepElement component.
 */
export const QuizCardWrapper = ({
  onClick,
  cardData,
  lightNext = false,
  count = 4,
}) => {
  /**
   * Click handler function that takes an item object parameter and invokes the onClick prop function with item as argument.
   * @param {object} item - Object representing data for quiz card.
   * @returns {void}
   */
  const onCardClick = (item) => {
    onClick(item);
  };

  return (
    <QuizCardWrapperContainer count={count}>
      {cardData.map((item, index) => (
        <QuizCard key={index} {...item} onClick={() => onCardClick(item)} />
      ))}

      <NextStepElement light={lightNext} onClick={() => onCardClick(null)} />
    </QuizCardWrapperContainer>
  );
};
