import Logo404 from "../../../images/logo404.png";
import Hovered from "../../Hovered/Hovered";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  const handleGoToHomePage = () => {
    navigate('/');
  }

  const handleGoBack = () => {
    navigate(-1);
  }

  return (
    <section className="not-found">
      <img className="not-found__logo" src={Logo404} alt='Логотип'></img>
      <p className="not-found__404">404</p>
      <p className="not-found__text">Страница не найдена</p>
      <Hovered>
        <button className="not-found__button" onClick={handleGoBack}>Вернуться назад</button>
      </Hovered>
      <Hovered>
        <button className="not-found__button" onClick={handleGoToHomePage}>на главную</button>
      </Hovered>
    </section>
  );
}

export default NotFound;