import ReviewLink from "../ReviewLink/ReviewLink";
import ReviewData from "../../utils/reviewConstants"

function ReviewList() {
  return (
    <section className="review-list">
      <p className="review-list_title">Почитайте отзывы о нас на:</p>
      <div className="review-list_items">
        {ReviewData.map((review) => {

          return (
            <ReviewLink
              key={review.id}
              data={review}
              link_name={review.link_name}
              link_url={review.link_url}
              img_src={review.img_src}
              disabled_img_src={review.disabled_img_src}
              disabled={review.disabled}
            />
          )
        })}
      </div>


    </section>
  )
};
export default ReviewList;