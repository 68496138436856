import Header from "../Header/Header";
import AlsoSee from "../AlsoSee/AlsoSee";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";
import Size from "../Size/Size";
import { useEffect, useState } from "react";
import cache from "../../utils/ItemCache";
import Description from "../Description/Description";
import cart from "../../utils/CartCore";

// TODO: модал - получает стейт открыт или закрыт, функцию закрытия, и картинку, функцию закрытия по кнопке Эскейп
// TODO: получить данные продукта из локалстореджа
// { getCardInfo }
function CardInfo({ onCallMe, onCardClick, setCartItemCount }) {
  const { id } = useParams();
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentSize, setCurrentSize] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [buyButtonText, setBuyButtonText] = useState("В корзину");
  const [buyButtonDisabled, setBuyButtonDisabled] = useState(false);
  // const idNumber = Number(id);
  // const currentProduct = getCardInfo(idNumber);
  // console.log('card:', currentProduct);

  const refreshBuyButton = (isInCart) => {
    setBuyButtonDisabled(isInCart);
    setBuyButtonText(isInCart ? 'В корзине' : 'В корзину');
  }

  const checkIfInCart = (productId, sizeId) => {
    refreshBuyButton(cart.has(productId, sizeId))
  }

  useEffect(() => {
    cache.getCardInfo(id)
      .then((product) => {
        setCurrentProduct(product);
        const firstSize = product.sizes[0];
        const firstPrice = product.sizes[0].price;
        setCurrentSize(firstSize);
        setCurrentPrice(firstPrice);
        setCurrentImage(firstSize.images[0]);
        checkIfInCart(product.productId, firstSize._id);
      })
      .catch(err => console.log(err));
  }, [])

  if (currentProduct === null) {
    console.log("IF enter")
    return (<></>)
  }
  console.log("каррент проодукт", currentProduct);


  const handleImageClick = () => {
    onCardClick(currentImage);
  }

  const setSize = (size) => {
    setCurrentSize(size);
    setCurrentImage(size.images[0]);
    checkIfInCart(currentProduct.productId, size._id);
  }

  const setPrice = (price) => {
    setCurrentPrice(price);
  }

  const onBuyButtonClick = () => {
    setCartItemCount(currentProduct.productId, currentSize._id, 1);
    refreshBuyButton(true);
  }

  return (
    <section className="card-info">
      <Header
        onCallMe={onCallMe} />
      <div className="card-info__banner"></div>
      <div className="card-info__container">
        <div className="card-info__image-container">
          <img
            className='card-info__image'
            alt='Изображение товара'
            name={currentProduct.name}
            src={currentImage}
            onClick={handleImageClick}
          >
          </img>
          <div className="card-info__preview-container">
            {
              currentSize.images.map((image) => (
                <img
                  key={image.index}
                  className='card-info__preview-image'
                  alt='Изображение товара'
                  src={image}
                  onClick={() => { setCurrentImage(image) }}>
                </img>
              ))
            }

          </div>
        </div>
        <div className='card-info__details'>
          <h2 className='card-info__title'>{currentProduct.name}</h2>
          <p className='card-info__code'>{currentProduct.code}</p>
          <p className="card-info__price">{currentSize.price.toLocaleString()} руб.</p>
          <div className="card-info__buttons">
            {/* <div className="card-info__quantity-container">
              <div className="card-info__minus">-</div>
              <div className="card-info__quantity">1</div>
              <div className="card-info__plus">+</div>
            </div> */}
            {/* //TODO: онКлик - сохраняет в локалсторадж айди + количество */}
            <button className={`card-info__buy-button${buyButtonDisabled ? " card-info__buy-button_disabled" : ""}`} onClick={onBuyButtonClick}>{buyButtonText}</button>
          </div>
          {
            currentProduct.productType === "puzzle" ? (<Size properties={currentProduct} setSize={setSize} />) : ''
          }
          <div className="card-info__properties">
            <p className='card-info__title'>О товаре:</p>
            <p className='card-info__title'>Материал: {currentProduct.material}</p>
            <p className='card-info__title'>Гарантия: {currentProduct.warranty} мес.</p>
            <p className='card-info__title'>Цвет: {currentSize.color}</p>
            <p className='card-info__title'>Размер упаковки: {currentSize.packingSize} см.</p>
            <p className='card-info__title'>Вес: {currentSize.weight} кг.</p>
          </div>
        </div>
      </div>
      <Description
        productType={currentProduct.productType}
        productCollection={currentProduct.productCollection}
      />

      {/* <AlsoSee cardItems={puzzles}/> */}
      <Footer />

    </section>
  );
}

export default CardInfo;