import { Link } from "react-router-dom";
import MissionPic from "../../../../images/mission.jpg";
import Hovered from "../../../Hovered/Hovered";

function Mission({handleViewOnTop}) {

  return (
    <section className="mission">
      <div className='mission__block'>
        <div className='mission__description-container'>
          <h2 className='mission__title'>
            Вкладываем любовь и теплоту души
          </h2>
          <h3 className='mission__subtitle'>в каждое изделие</h3>
          <p className='mission__text'>
            Barkwood - это, прежде всего, возможность получить уникальные изделия
            в оговоренные сроки по справедливым ценам.
            Мы не являемся посредниками, заказывать у нас удобно и выгодно
          </p>
          <Hovered>
            <Link to={`/history`}>
              <button className='mission__button' onClick={handleViewOnTop}>Читать нашу историю</button>
            </Link>
          </Hovered>
        </div>
        <img className='mission__image-container' alt='Наша миссия' src={MissionPic}></img>
      </div>
    </section>
  );
}

export default Mission;