import Banner from "../../Banner/Banner";
import Footer from "../../Footer/Footer";
import Bestsellers from "./Bestsellers/Bestsellers";
import CatalogClasses from "./CatalogClasses/CatalogClasses";
import Comments from "./Comments/Comments";
import Discount from "./Discount/Discount";
import Formula from "./Formula/Formula";
import Mission from "./Mission/Mission";
import More from "./More/More";

function Main({ decor, setCartItemCount, onCallMe, handleViewOnTop }) {

  return (
    <div className="main">
      <Banner
        onCallMe={onCallMe}
        title='Оптимальные решения'
        subtitle='для комфорта и уюта в Вашем доме'
        buttonText='Перейти в каталог'
        className='banner__main'
        to='/catalog'
      />
      <CatalogClasses />
      {/* <Discount /> */}
      <Mission 
        handleViewOnTop={handleViewOnTop}/>
      <Bestsellers
        setCartItemCount={setCartItemCount}
        cardItems={decor} />

      <Formula />
      <Comments />
      <More
        handleViewOnTop={handleViewOnTop}
      />
      <Footer />
    </div>
  );
}

export default Main;