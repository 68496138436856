import { useEffect, useState } from "react";
import Header from "../../Header/Header";
import GalleryItem from "./GalleryItem/GalleryItem";

import GalleryBlock from "./GalleryBlock/GalleryBlock";
import { getGallery } from "../../../utils/Api";

function Gallery({onCallMe}) {
  const [gallery, setGalery] = useState([]);

  useEffect(() => {
    getGallery()
      .then((projects) => {
        console.log("projects", projects)
        setGalery(projects)
      })
      .catch(err => console.log(err))
  }, []);

  return (
    <section className="gallery">
      <Header
        onCallMe={onCallMe}
      />
      <div className="gallery__banner"></div>
      <h2 className="gallery__title">Фотогалерея наших работ:</h2>
      <div className="gallery__items">
        {/* {gallery?.map((galleryItem) => {
          return (<GalleryItem
            key={galleryItem._id}
            properties={galleryItem}
          />)
        })} */}

        {gallery?.map((galleryItem) => {
          return (<GalleryBlock
            key={galleryItem._id}
            properties={galleryItem}
          />)
        })}
      </div>
    </section>
  );
}

export default Gallery;