import styled from "styled-components";

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;

export const Icon = styled.svg``;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  /* Скрываем оригинальный чекбокс */
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: ${({ large }) => (large ? "1.9rem" : "1rem")};
  height: ${({ large }) => (large ? "1.9rem" : "1rem")};
  background: ${(props) => (props.checked ? "#94A389" : "transparent")};
  border: ${({ large }) => (large ? "1px" : "3px")} solid #94a389;
  transition: all 150ms;
`;
