import logo from '../../images/Logo3.svg';

function Logo() {
  return (
    <section className='logo'>
      <img src={logo} alt="Логотип Компании" className='logo__pic'></img>
    </section>
  );
}

export default Logo;