import styled from "styled-components";

const CardImage = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-height: calc(15vw);
  width: 100%;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  border-radius: 12px;

  color: #ffffff;
  background-image: url(${({ imageSrc }) => imageSrc || "blue"});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${({ imageSrc }) => (imageSrc ? "transparent" : "#94a389")};
  border: 1px solid #94a389;
  transition: all 350ms ease-in-out;

  @media screen and (max-width: 1290px) {
    min-height: calc(20vw);
  }
  
  @media screen and (max-width: 800px) {
    min-height: calc(22vw);
  }
  
  @media screen and (max-width: 600px) {
    min-height: calc(42vw);
  }
  
  @media screen and (max-width: 400px) {
    min-height: calc(80vw);
  }
`;

const CardTitle = styled.h3`
  margin: 0;
  user-select: none;
  margin-top: 0.2rem;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.2rem;
  text-align: center;

  color: #4e4844;
`;

const SubImageTitle = styled.p`
  margin: 0;
  margin-top: 0.2rem;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 2rem;
  text-align: center;
  color: white;
`;

const CardContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &:hover ${CardImage} {
    filter: brightness(80%);
    transition: all 0.3s ease-in-out;
  }
`;

export const QuizCard = ({
  image,
  title,
  imageTitle,
  subImageTitle,
  onClick,
}) => {
  return (
    <CardContainer onClick={onClick}>
      {imageTitle && (
        <CardImage>
          <div style={{ margin: "0 20%" }}>
            {imageTitle}
            {subImageTitle && <SubImageTitle>{subImageTitle}</SubImageTitle>}
          </div>
        </CardImage>
      )}
      {!imageTitle && <CardImage imageSrc={image} alt="Картинка" />}
      <CardTitle>{title || " "}</CardTitle>
    </CardContainer>
  );
};
