import styled from "styled-components";
import { palette } from "../palette";

export const BannerContainer = styled.section`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
`;

export const BannerImage = styled.div`
  background-image: url(${(props) => props.imageSrc});
  background-size: cover;
  background-repeat: no-repeat;
  height: 650px;
  min-width: 100%;
  width: 100%;
`;

export const BannerHeader = styled.div`
  z-index: 1;
  background-color: ${palette.colors.green_dark};
  width: 100%;
  min-height: 20px;
  height: 20px;
`;

export const Container = styled.div``;
