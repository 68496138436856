import styled from "styled-components";
import { palette } from "../palette";

const Form = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(42px, 8%) 1fr;
`;
const LeftSide = styled.div`
  background-color: ${palette.colors.green_dark};
  min-width: 42px;
  width: 100%;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 38px 56px 38px 56px;
  background-color: ${palette.colors.gray_1};

  @media screen and (max-width: 600px) {
    padding: 16px 24px;
  }
`;

export const FormWrapper = ({ children }) => {
  return (
    <Form>
      <LeftSide />
      <RightSide>{children}</RightSide>
    </Form>
  );
};
