export const palette = {
  colors: {
    default: "#F1E4DC",
    default_dark: "#BD9F8F",
    green: "#B7CE96",
    green_dark: "#94A389",
    purple: "#7D2990",
    txt_gray: "#7C7C7C",
    bg_gray: "#EEEEEE",
    gray_1: "#F5F5F5",
  },
};