import { QuizCardWrapper } from "../../QuizCardWrapper/QuizCardWrapper";

import Image1 from "../../../../../images/quiz_img/quiz_2_shape_straigt.webp";
import Image2 from "../../../../../images/quiz_img/quiz_2_shape_corner.webp";
import Image3 from "../../../../../images/quiz_img/quiz_2_shape_u_shape.webp";
import Image4 from "../../../../../images/quiz_img/quiz_2_shape_island.webp";

export const Step1 = ({ onClick }) => {
  const data = [
    {
      image: Image1,
      title: "Прямая",
    },
    {
      image: Image2,
      title: "Угловая",
    },
    {
      image: Image3,
      title: "П - образная",
    },
    {
      image: Image4,
      title: "С островом",
    },
  ];

  const onCardClick = (data) => {
    onClick(data);
  };
  return (
    <QuizCardWrapper cardData={data} onClick={onCardClick}></QuizCardWrapper>
  );
};
