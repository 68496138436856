import styled from "styled-components";

export const PageContainer = styled.div`
  min-height: calc(100vh - 202px);

  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  /* @media screen and (max-width: 1440px) {
    width: 90%;
  } */
`;

export const ProductsSections = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fff8f4;
`;
