function PillowText() {
  return (
      <section className="pillow-text">
        <div className="card-info__text-container">
          <h3 className="card-info__text-title">
            Комплект подушек для растущего стульчика. Нежность, безопасность и радость для ваших детей.
          </h3>
          <p className="card-info__text-description">
            Для каждого родителя забота о комфорте и безопасности своего ребенка является приоритетом. 
            Именно поэтому мы предлагаем вам ознакомиться с нашей коллекцией мягких подушек, которые 
            не только обеспечивают комфортную посадку вашего малыша, но и созданы с любовью и аккуратностью, 
            с учетом всех необходимых требований безопасности. 
          </p>
          <p className="card-info__text-description">
            Мы гордимся тем, что наши мастера обладают многолетним опытом и тщательно следят за каждым шагом 
            производства, чтобы обеспечить высокое качество и надежность. Каждая подушка проходит строгий 
            контроль качества, чтобы удовлетворить самые высокие стандарты.  Наши подушки изготовлены из 
            натурального хлопка, который обеспечивает мягкость и приятный на ощупь материал. 
            Также используем экологический наполнитель холофайбер, который является гипоаллергенным и безопасным 
            для детей всех возрастов.
          </p>
          <p className="card-info__text-description">
            Мы понимаем, что дети любят яркие и забавные вещи, поэтому наши подушки доступны в разнообразных 
            ярких цветах и дизайнах. Наши дизайнеры тщательно выбирают ткани с привлекательными узорами, 
            чтобы подушки были не только удобными, но и визуально привлекательными. Ваш ребенок будет в 
            восторге от выбора подушки с его любимым персонажем или забавным узором.
          </p>
        </div>
      </section>
  );
}

export default PillowText;