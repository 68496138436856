import Popup from "../Popup/Popup";

function ImagePopup({isOpen, onClose, selectedCard}) {
  // console.log(card);
  return (
    <Popup
      popupType="image"
      isOpen={isOpen}
      onClose={onClose}
      containerType="popup__image-container"
    >
      <img className="popup__image" src={selectedCard} alt="Карточка товара"></img>
    </Popup>
  )
}

export default ImagePopup;
