import { Checkbox } from "../../CheckboxComponent/CheckboxComponent";
import { ContactForm } from "../../ContactForm/ContactForm";
import cloneDeep from "lodash.clonedeep";
import { useEffect, useState } from "react";
import { palette } from "../../palette";
import styled from "styled-components";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 60vh;
  gap: 3rem;
`;
const Option = styled.span`
  display: flex;
  gap: 1.3rem;
  align-items: center;
  cursor: pointer;
`;
const Label = styled.p`
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2rem;
  color: ${palette.colors.txt_gray};
  margin: 0;
  @media screen and (max-width: 1440px) {
    font-size: 1.1rem;
  }
`;
const Title = styled.h1`
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 2rem;
  color: ${palette.colors.txt_gray};
  margin: 0;
  @media screen and (max-width: 1440px) {
    font-size: 1.5rem;
  }
`;

const ColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 3rem 0;

  gap: 8rem;
  width: 100%;
  @media screen and (max-width: 1440px) {
    gap: 4rem;
  }

  @media screen and (max-width: 1290px) {
    grid-template-columns: 1fr;
  }
  
`;
const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3rem 3rem;

  @media screen and (max-width: 1290px) {
    padding: 0 1rem 1rem;
  }
`;

export const Step9 = ({ quizData, setQuizData }) => {
  const [data, setData] = useState([
    {
      label: "Рассрочка 0% - платите частями без переплат",
      checked: false,
    },
    { label: "Подбор кухонной техники и доставка", checked: false },
    {
      label: "Технический проект (укажем розетки, вентиляцию, плитку)",
      checked: false,
    },
  ]);

  const onCheckboxClick = (item) => {
    const itemIndex = data.findIndex(
      (dataItem) => dataItem.label === item.label,
    );
    if (itemIndex === -1) {
      return;
    }
    const checkedItem = { ...data.at(itemIndex) };
    checkedItem.checked = !checkedItem.checked;

    const newData = cloneDeep(data);
    newData.splice(itemIndex, 1, checkedItem);

    setData(newData);
  };

  useEffect(() => {
    const lastStepData = data.reduce((acc, curr, index) => {
      if (curr.checked) {
        acc += `${index !== 0 ? ", " : ""}${curr.label}`;
      }
      return acc;
    }, "");
    setQuizData({
      ...quizData,
      UF_CRM_QUIZ_10: lastStepData.length ? lastStepData : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <StepContainer>
      <ColumnsContainer>
        <Column>
          <Title>Требуются ли вам дополнительные услуги?</Title>
          {data.map((item, index) => (
            <Option
              key={index}
              onClick={() => {
                onCheckboxClick(item);
              }}
            >
              <Checkbox large checked={item.checked} />
              <Label>{item.label}</Label>
            </Option>
          ))}
        </Column>

        <Column>
          <ContactForm data={quizData} />
        </Column>
      </ColumnsContainer>
    </StepContainer>
  );
};
