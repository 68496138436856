import { palette } from "../palette";
import styled from "styled-components";

export const Title = styled.h1`
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  margin: 0;
  color: #4e4844;

  @media screen and (max-width: 1160px) {
    width: 80%;
  }

  @media screen and (max-width: 800px) {
    width: 60%;
  }
`;
export const Container = styled.div`
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Divider = styled.hr`
  border-bottom: 1px solid ${palette.colors.green_dark};
  width: 90%;
`;
export const GoBackBtn = styled.button`
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: right;
  bottom: 1rem;
  left: 9rem;
  position: absolute;
  color: #7c7c7c;

  @media screen and (max-width: 1290px) {
    left: 6rem;
  }

  @media screen and (max-width: 780px) {
    bottom: -3rem;
  }

`;

export const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  width: 90%;
  color: #59524e;
  text-align: center;
  color: var(--accent-color);

  @media screen and (max-width: 1160px) {
    width: 80%;
  }
  
  @media screen and (max-width: 800px) {
    width: 60%;
  }
`;
