// import Card from "../../../Card/Card";
import { Link } from "react-router-dom";
import Carousel from "../../../Сarousel/Carousel";

function Bestsellers({ cardItems, setCartItemCount }) {
  return (
    <section className="bestsellers">
      <div className='besrsellers__title-block'>
        <h2 className='bestsellers__title'>Наши бестселлеры</h2>
        <Link to={`/puzzles`}>
          <button className='bestsellers__button'>Все товары</button>
        </Link>
      </div>
      <div className='bestsellers__showcase'>
        <Carousel
          setCartItemCount={setCartItemCount}
          cardItems={cardItems} />
        {/* {cardItems.length >= 3 ?
          <>
            <Card properties={cardItems[0]} />
            <Card properties={cardItems[1]} />
            <Card properties={cardItems[2]} />
          </> : <></>} */}
      </div>
    </section>
  );
}

export default Bestsellers;