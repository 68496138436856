import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";


function ForBuyers({onCallMe}) {
  return (
      <section className="for-buyers">
        <Header
          onCallMe={onCallMe}
        />
        <div className="for-buyers__banner"></div>
        <div className="for-buyers__container">
          <div className="for-buyers__delivery">
            <h2 className="for-buyers__title">Доставка</h2>
            <h3 className="for-buyers__subtitle">Способ доставки</h3>
            <p className="for-buyers__text">
              Доставка по России осуществляется транспортными компаниями ПЭК, СДЭК и другими известными перевозчиками на ваш выбор
            </p>
            <p className="for-buyers__text">
              Доставка по Москве и московской области осуществляется собственной курьерской службой
            </p>
            <h3 className="for-buyers__subtitle">Стоимость доставки</h3>
            <p className="for-buyers__text">
              Стоимость доставки составляет 490 рублей по всей России
            </p>
            <h3 className="for-buyers__subtitle">Срок доставки</h3>
            <p className="for-buyers__text">
              Срок доставки по Москве и московской области составляет 1-3 дня.
            </p>
            <p className="for-buyers__text">
              По России - 5-7 дней (в зависимости от региона России)
            </p>
          </div>
          <div className="for-buyers__payment">
            <h2 className="for-buyers__title">Оплата</h2>
              <h3 className="for-buyers__subtitle">Для заказов из Москвы и МО</h3>
              <p className="for-buyers__text">
                Для заказов из Москвы и МО доступна оплата наличными курьеру при получении и проверке товара, 
                а также предоплата картой при оформлении заказа на сайте
              </p>
              <h3 className="for-buyers__subtitle">Для заказов из других регионов России</h3>
              <p className="for-buyers__text">
                Для заказов из других регионов России требуется полная предоплата заказа. 
                В случае, если товар Вам не подошел, вы можете вернуть его в течение 14 дней с момента получения заказа. 
              </p>
          </div>
        </div>
        <Footer />
      </section>
  );
}

export default ForBuyers;