
function Services (props) {
  return (
      <section className="services">
        <h2 className="services__title">{props.title}</h2>
        <div className="services__table">
          <div className="services__table-item">{props.stage1}</div>
          <div className="services__table-item">{props.stage2}</div>
          <div className="services__table-item">{props.stage3}</div>
          <div className="services__table-item">{props.stage4}</div>
          <div className="services__table-item">{props.stage5}</div>
          <div className="services__table-item">{props.stage6}</div>
          <div className="services__table-item">{props.stage7}</div>
          <div className="services__table-item">{props.stage8}</div>
          <div className="services__table-item">{props.stage9}</div>
          <div className="services__table-item">{props.stage10}</div>
        </div>
      </section>
  );
}

export default Services;