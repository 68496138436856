import React, { useState } from "react";
import { StyledInput } from "./styles";

export const InputComponent = ({
  label,
  value,
  onChange,
  error,
  helperText,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <StyledInput
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      $hasValue={focused || value.toString().length}
      variant="filled"
      label={label}
      value={value}
      onChange={handleChange}
      error={error}
      helperText={helperText}
      {...rest}
    />
  );
};
