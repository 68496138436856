import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import faqList from "../../../utils/faq/faqList";
import FaqGroup from './FaqGroup/FaqGroup';

function Faq() {

  return (
    <section className="faq">
      <Header />
      <div className="faq__banner"></div>
      <div className="faq__container">
        <h2 className="faq__title">Ответы на вопросы</h2>
        {
          faqList.map((block) => {
            return (
              <FaqGroup
                key={block.id}
                blockData={block}
              />
            )
          })
        }
      </div>
      <Footer />
    </section>
  );
};

export default Faq;