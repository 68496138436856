import { QuizWrapper } from "./QuizWrapper/QuizWrapper";
import { PageContainer } from "./styles";
import { BannerComponent } from "./BannerComponent/BannerComponent";
import Image from "../../../images/kitchen5.jpg";
import { BannerContentQuiz } from "./BannerContents/BannerContents";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";

function QuizPage() {
  return (
    <PageContainer>
      <Header />
      <BannerComponent bannerUrl={Image}>
        <BannerContentQuiz />
      </BannerComponent>
      <QuizWrapper />
      <Footer />
    </PageContainer>
  );
};

export default QuizPage;