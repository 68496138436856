import { getProductById } from '../utils/Api';
import { CACHE_TIME_SECONDS } from "../utils/constants";

class ItemCache {

  constructor() {
    this.cache = new Map();
  }

  getCardInfo(id) {

    if (this.cache.has(id) && (new Date().getTime() - this.cache.get(id).updateTime.getTime()) / 1000 < CACHE_TIME_SECONDS) {
      console.log(`got from cache: ${this.cache.get(id).cardInfo}`);
      // Оборачиваем объект в Promise.resolve(), чтобы всегда возвращать промис
      return Promise.resolve(this.cache.get(id).cardInfo);
    } else {
      return getProductById(id)
        .then((product) => {
          console.log("got from server");
          this.cache.set(id, { updateTime: new Date(), cardInfo: product });
          return product;
        });
    }
  }
}

const cache = new ItemCache();

export default cache;