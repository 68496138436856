const faqList = [
  {
    id: "00001",
    name: "Детские растущие стульчики",
    qa: [
      {
        id: "000011",
        question: "Для какого возраста подходит растущий стул?",
        answer: "Наши стулья подходят для детей от 1,5 лет"
      },
      {
        id: "000012",
        question: "Из чего делают растущий стул?",
        answer: "Из натурального массива дерева"
      },
      {
        id: "000013",
        question: "Для чего подходит растущий стул?",
        answer: "Это по-настоящему унимверсальный и незаменимый предмет мебели для вашего ребенка. Он подходит для занятий, приема пищи, игр и для многого другого"
      },
    ]
  },
  {
    id: "00002",
    name: "Пазлы",
    qa: [
      {
        id: "000021",
        question: "Из чего сделаны пазлы?",
        answer: "Пазлы изготовлены из Натуральной древесной плиты (ХДФ)"
      },
      {
        id: "000022",
        question: "Есть ли подарочная упаковка?",
        answer: "Наши пазлы уже приезжают к вам в красивой деревянной упаковке"
      },
      {
        id: "000023",
        question: "Какие есть уровни сложности у пазлов, и сколько их собирать по времени?",
        answer: "У пазлов от 2х до 3х уровней сложности. Время сборки от 2-ух до 6-и часов"
      },
      {
        id: "000024",
        question: "С какого возраста можно собирать пазлы?",
        answer: "С любого. Но помните, что дети могут собирать пазлы только в присутствии взрослых"
      },
    ]
  },
  {
    id: "00003",
    name: "Разделочные доски",
    qa: [
      {
        id: "000031",
        question: "Из чего делаются разделочные доски?",
        answer: "Наши разделочные торцевые доски изготавливаются из массива ясеня"
      },
      {
        id: "000032",
        question: "Можно ли мыть доску в посудомойке?",
        answer: "Нет. При покупке наших досок, вы получите инструкцию по уходу за изделием с подробным описанием"
      },
      {
        id: "000033",
        question: "Как ухаживать за разделочной доской?",
        answer: "Раз в месяц необходимо пропитывать вазелиновым минеральным маслом"
      },
    ]
  },
];

export default faqList;