import { Link } from "react-router-dom";
import Hovered from "../Hovered/Hovered";

function Now() {
  return (
    <section className="now">
      <h2 className="now__title">BARKWOOD <span className="accent">сейчас</span></h2>
      <div className="now-items">
        <div className="now__item now__item_accent">
          <h2 className="now__item-title now__item-title_accent">1200м2</h2>
          <h3 className="now__item-subtitle now__item-subtitle_accent">производственных площадей</h3>
        </div>
        <div className="now__item">
          <h2 className="now__item-title">36</h2>
          <h3 className="now__item-subtitle">квалифицированных работников</h3>
        </div>
        <div className="now__item">
          <h2 className="now__item-title">Качество</h2>
          <h3 className="now__item-subtitle">постоянные улучшения производственного процесса и контроль качества</h3>
        </div>
        <div className="now__item now__item_noborder">
          <h2 className="now__item-title">BARKWOOD,
            все лучшее для Вас</h2>
          <Hovered>
            <Link to="/catalog">
              <button className="now__button">Перейти в каталог</button>
            </Link>
          </Hovered>
        </div>
      </div>
    </section>
  );
}

export default Now;