import Banner from "../../Banner/Banner";
import Footer from "../../Footer/Footer";
import CardList from "../../CardList/CardList";
// import AlsoSee from "../../AlsoSee/AlsoSee";
import FilterButton from "../../FilterButton/FilterButton";
import { useEffect, useState } from "react";

function Puzzles({ puzzles, setCartItemCount, onCallMe }) {
  const [selectedFilter, setSelectedFilter] = useState('');
  const [puzzlesToSee, setPuzzlesToSee] = useState(puzzles);
  const [filtersArray, setFiltersArray] = useState([]);

  const handleSetFilters = (category) => {
    setSelectedFilter(category);
  };

  const handleResetFilters = () => {
    setSelectedFilter('');
  };

  const updatePuzzlesToSee = () => {
    setPuzzlesToSee(puzzles.filter(puzzle => puzzle.productCollection.includes(selectedFilter)));
  };

  const initFilters = () => {
    const notFilteredCategories = puzzles.map((item) => item.productCollection);

    puzzles.forEach((item) => {
      setFiltersArray((categoryArray) =>
        [
          ...categoryArray,
          item.productCollection
        ]
      )
    });

    setFiltersArray(
      notFilteredCategories.reduce((acc, item) => {
        if (acc.includes(item)) {
          return acc;
        }
        return [...acc, item];
      }, [])
    )
  }

  useEffect(() => {
    if (puzzles || puzzles !== []) {
      initFilters();
      updatePuzzlesToSee();
    }
  }, [puzzles]);

  useEffect(() => {
    updatePuzzlesToSee();
  }, [selectedFilter])

  return (
    <section className="puzzles">
      <Banner
        onCallMe={onCallMe}
        title='Деревянные пазлы'
        subtitle='для всей семьи'
        buttonText='В каталог'
        className='banner__puzzles'
        to='/catalog'
      />
      <div className="puzzles__container">
        <p className="puzzles__text">
          Здесь вы найдете уникальные коллекции пазлов на разные тематики. Пазлы сделаны из эко-материалов, помогают справиться с лишним стрессом,
          развивают логику и мелкую моторику. А еще, это - увлекательный и оригинальный подарок.
        </p>
        <div className="puzzles__filter">
          <FilterButton
            title={"Все"}
            handleClick={handleResetFilters}
          />
          {
            filtersArray?.map((category) => {
              return (
                <FilterButton
                  key={category.index}
                  title={category}
                  handleClick={handleSetFilters}
                />
              )
            })
          }
        </div>
        <CardList
          cardItems={puzzlesToSee}
          setCartItemCount={setCartItemCount} />
      </div>

      {/* <AlsoSee
        key={puzzles.productId}
        cardItems={puzzles}
      /> */}
      <Footer />
    </section>
  );
}

export default Puzzles;

