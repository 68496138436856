import Banner from "../../Banner/Banner";
import Team from "../../../images/about2.png";
import Avatar from "../../../images/avatar.jpg";
import Child from "../../../images/history-pic.png"
import Slogan from "../../Slogan/Slogan";
// import Video from "../../Video/Video";
import Now from "../../Now/Now";
import Footer from "../../Footer/Footer";

function History({onCallMe}) {
  return (
    <section className="history">
      <Banner
        onCallMe={onCallMe}
        title='BARKWOOD'
        subtitle='История создания'
        buttonText='Читать историю'
        className='banner__history'
      />
      <Slogan
        title='BARKWOOD —'
        // slogan='уютная семейная столярная мастерская'
        slogan='оптимальные решения для комфорта и уюта в вашем доме'
        text='Главный наш принцип — это использование только натуральных материалов 
          для производства наших изделий. BARKWOOD, с любовью к каждому.'
      />
      <div className='history__block'>
        <img className='history__image-container' alt='Детский стульчик' src={Team}></img>
        <div className='history__story-container history__story-container_accent'>
          <div className='history__avatar-block'>
            <img className='history__avatar' alt='Аватар основателя' src={Avatar}></img>
            <div className='history__name'>
              <p className='history__name-text history__text_accent'>
                BARKWOOD - это молодой бренд,
              </p>
              <p className='history__name-text history__text_accent'>
                объединяющий стиль,
              </p>
              <p className='history__name-text history__text_accent'>
                качество и безопасность.
              </p>
            </div>
          </div>
          <h2 className='history__title history__title_accent'>Мы гордимся нашей командой, которая с
            профессионализмом и любовью относится к своей работе.</h2>
          <p className='history__text history__text_accent'>
            Мы придаем огромное значение каждому этапу создания наших продуктов:
            от начальных переговоров с клиентом до финальной реализации проекта.
          </p>
          <p className='history__text history__text_accent'>Одним из наших главных преимуществ является
            использование высокоточного оборудования, которое позволяет нам достичь качества и
            точности в каждом изделии.
          </p>
        </div>
      </div>
      <div className='history__block'>
        <img className='history__image-container' alt='Детский стульчик' src={Child}></img>
        <div className='history__story-container'>
          <h2 className='history__title'>
            Каждый человек заслуживает жить в здоровой и безопасной среде.
          </h2>
          <p className='history__text'>
            Понимая важность заботы о природе, мы стремимся создавать продукцию,
            которая не только прекрасно впишется в вашу жизнь, но и будет безопасной для нашей планеты.
            Именно поэтому используем экологически чистые материалы и процессы производства,
            чтобы минимизировать наш экологический след.
          </p>
          <p className='history__text'>
            BARKWOOD - это готовые решения, которые будут радовать вас долгие годы. Но также наша
            команда не боится принимать вызовы и готова воплотить ваши идеи в жизнь.
            Обращайтесь к нам и убедитесь сами в качестве нашей продукции!
          </p>
        </div>
      </div>
      {/* <Video /> */}
      <Slogan
        pretitle='Для производства изделий'
        title='мы тщательно отбираем'
        slogan='дерево самого высокого качества'
        text='Наша задача показать вам, что мебель имеет гораздо больший смысл, чем ее привычное назначение. 
          Мы подчёркиваем природную красоту форм и показываем ее под нужным углом. Так, предметы интерьера 
          взаимодействуют с пространством и наполняют своей энергетикой каждого.'
      />
      <Now />
      <Footer />
    </section>
  );
}

export default History;