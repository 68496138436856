import { palette } from "../palette";
import styled from "styled-components";

export const Button = styled.button`
  padding: ${(props) => props.styles?.padding || "0.9rem"};
  background-color: ${palette.colors.green_dark};
  width: ${(props) => props.styles?.width || "100%"};
  color: ${(props) => palette.colors[props.styles?.txt_color || "gray_1"]};
  display: flex;
  font-size: ${(props) => props.styles?.fontSize || "1rem"};
  align-items: center;
  outline: none;
  border: 1px solid ${palette.colors.green_dark};
  border-radius: ${(props) => (props.rounded ? "4px" : 0)};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: white;
    border: 1px solid ${palette.colors.default_dark};
    color: ${palette.colors.default_dark};
  }
  &:active {
    background-color: ${palette.colors.green_dark};
    color: white;
  }

  &:disabled {
    background-color: ${palette.colors.bg_gray};
    color: ${palette.colors.txt_gray};
    border: 1px solid ${palette.colors.txt_gray};
    cursor: not-allowed;
  }
`;

export const ShadowButton = styled(Button)`
  background-color: transparent;
  color: ${palette.colors.green_dark};
  display: flex;
  border: 1px solid transparent;

  &:hover {
    background-color: ${palette.colors.green_dark};
    border: 1px solid ${palette.colors.green_dark};
    color: white;
  }
  &:active {
    transform: scale(1.01);
    transition: all 0.3s ease-in-out;
    background-color: ${palette.colors.green_dark};
    color: white;
  }
`;
