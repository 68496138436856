function DecorText() {
  return (
      <section className="decor-text">
        <div className="card-info__text-container">
          <h3 className="card-info__text-title">Уникальный декор для вашего дома: сочетание стиля, качества и инноваций</h3>
          <p className="card-info__text-description">
            В поисках идеального декора для вашего дома? Наша компания предлагает широкий ассортимент уникальных изделий 
            декора, которые подчеркнут вашу индивидуальность и создадут уютную атмосферу в вашем жилище. Мы гордимся тем, 
            что предлагаем как ручные, сделанные с душой и любовью изделия, так и фабричные, изготовленные на современном 
            оборудовании. В каждом изделии мы сочетаем эстетику и функциональность, отражая современные тенденции и инновационные решения. 
          </p>
          <p className="card-info__text-description">
            Мы предлагаем фабричные изделия, которые создаются с использованием современного оборудования и передовых технологий. 
            Используем только высококачественные материалы, чтобы обеспечить долговечность и превосходное качество каждого изделия. 
          </p>
          <p className="card-info__text-description">
            Наша команда дизайнеров уделяет особое внимание деталям, чтобы каждое изделие стало не только визуальным акцентом вашего 
            интерьера, но и служило вам долгие годы. Мы всегда следим за модными тенденциями и постоянно внедряем новые идеи и технологии, 
            чтобы предложить вам самые актуальные и современные решения. 
          </p>
        </div>
      </section>
  );
}

export default DecorText;
