import Banner from "../../Banner/Banner";
import Quiz from "../../../images/quiz2.jpg";
import Arrow from "../../../images/quiz-arrow.png";
// import DarkArrow from "../../images/quiz-arrow-dark.png";
import Stage from "../../Stage/Stage";
import Stage2_1 from '../../../images/stage2_pic1.png';
import Stage2_2 from '../../../images/stage2_pic2.png';
import Stage2_3 from '../../../images/stage2_pic3.png';
import Stage2_4 from '../../../images/stage2_pic4.png';
import Manufacture from '../../../images/manufacture.png';
import Manufacture2 from '../../../images/manufacture2.png';
import Manufacture3 from '../../../images/manufacture5.png';
import Manufacture4 from '../../../images/manufacture4.png';
import Result1 from '../../../images/result1.png';
import Result2 from '../../../images/result2.png';
import Result3 from '../../../images/result3.png';
import Result4 from '../../../images/result4.png';
import Hovered from "../../Hovered/Hovered";
import Footer from "../../Footer/Footer";
import Presentation from "../../Presentation/Presentation";
import PresentationPic from '../../../images/presentation-pic.png';
import { Link } from "react-router-dom";



function Kitchens({ onCallMe }) {
  return (
    <section className="kitchens">
      <Banner
        to="/quiz"
        onCallMe={onCallMe}
        title='BARKWOOD'
        subtitle='Создаем кухни по индивидуальным проектам'
        buttonText='Подобрать кухню'
        className='banner__kitchens'
      />
      <p className="kitchens__text">
        Мы используем только качественные материалы и фурнитуру. Наши дизайнеры с легкостью помогут
        Вам определиться с образом кухни, учитывая все Ваши пожелания. Кроме того, немалую роль мы
        отводим функциональности. Кухня должна быть не только красива внешне, но и удобна и практична
        в ежедневной эксплуатации.
      </p>
      <div className='kitchens__quiz-block'>
        <Hovered>
          <Link to="/quiz">
            <img className='kitchens__quiz-image' alt='Изображение кухни' src={Quiz}></img>
          </Link>
        </Hovered >


        <div className='kitchens__text-block'>
          <h2 className='kitchens__quiz-title'>
            Получите дизайн-проект и стоимость вашей кухни, заполнив анкету из нескольких вопросов,
            и  указав способ обратной связи с нами!
          </h2>
          <Link to="/quiz" className="kitchens__quiz-link">
            <img className='kitchens__quiz-pic' alt='редирект на квиз' src={Arrow}></img>
          </Link>
        </div>
      </div>
      <h2 className="kitchens__title">ОТ ЭСКИЗА ДО РЕЗУЛЬТАТА</h2>
      <p className="kitchens__text">
        Здесь мы хотим показать как мы работаем с клиентами, от эскиза до конечного результата.
        Прежде всего мы обсуждаем с клиентом какие у него ожидания от кухни: что ему больше всего нравится,
        какими готовыми работами он вдохновляется, какие задачи он хочет решить (напирмер, клиенту необходимо
        большое количество систем функционального хранения.. или наоборот, хочется минимум мебели и максимум свободного
        пространства), какая цветовая гамма лучше подойдет под общий интерьер и т.д. Затем мы осматриваем и тщательно
        замеряем нужную площадь, чтобы сделать первый набросок. И переходим к работе.
      </p>

      <Presentation
        title='ЭТАП 1. ЭСКИЗ'
        text1='Проводим замер помещения в котором планируется разместить будущую мебель с привязкой к имеющимся (планируемым) коммуникациям'
        text2='Определяем геометрию кухни, основные материалы, цвета, тип столешницы, фурнитуру и т.п.'
        text3='Описываем и замеряем встроенную/отдельно стоящую технику (при её наличии), либо фиксируем будущий выбор'
        text4='Делаем эскиз "от руки" у клиента на объекте и передаем на производство для формирования графического эскиза и калькуляции заказа'
        img={PresentationPic}
      />
      <Stage
        title='ЭТАП 2. ПРОЕКТ'
        text='После заключения договора в работу вступает проектировщик производства. По указанным размерам и данным эскиза моделируется
            технический проект. Все возникающие на этапе проектирования дополнительные вопросы сограсовываются с клиентом'
        img1={Stage2_1}
        img2={Stage2_2}
        img3={Stage2_3}
        img4={Stage2_4}
      />
      <Stage
        title='ЭТАП 3. ПРОИЗВОДСТВО'
        text='Производство мебели не простой процесс включающий в себя ряд ответственных этапов, основными из которых являются:
                1. разработка конструкторской документации на изделие на основании проекта 
                  (в том числе замера помещения/ места установки или монтажа изделия). 
                2. Закупка и раскрой необходимых материалов под детали будущей мебели. 
                3. Подбор требуемой фурнитуры. 
                4. Высверливание монтажных отверстий для сборки мебели. 
                5. Облицовка обрезных кромок (кромление). 
                6. Сборка и монтаж изделий (в том числе встроенной техники).'
        img1={Manufacture}
        img2={Manufacture2}
        img3={Manufacture3}
        img4={Manufacture4}
      />
      <Stage
        title='ЭТАП 4. РЕЗУЛЬТАТ'
        text='Приемка готового изделия на объекте у заказчика с проверкой функциональной готовности мебели и пуско-наладки встроенной техники. 
                На этом этапе между заказчиком и исполнителем оформляются акт готовых работ и приемо-сдаточные документы.'
        img1={Result1}
        img2={Result2}
        img3={Result3}
        img4={Result4}
      />
      <Footer />
    </section>
  );
}

export default Kitchens;