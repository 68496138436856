import Banner from "../../Banner/Banner";
import Footer from "../../Footer/Footer";
import Collage from "../../Collage/Collage";

function Electricity ({onCallMe}) {
  return (
      <section className="electricity">
        <Banner
          onCallMe={onCallMe}
          title='BARKWOOD'
          subtitle='Электроснабжение"'
          buttonText='Заказать расчет'
          className='banner__electricity'
        />
        <h2 className="electricity__title">Электрокоммуникации "под ключ"</h2>
        <Collage
          paragraph1='Мы предлагаем полный комплекс услуг по электромонтажу под ключ, включая монтаж  как в квартирах, так и в частных домах.'
          paragraph2='Наша команда профессионалов обеспечит качественное и безопасное выполнение всех работ. Мы заботимся о вашей безопасности, поэтому при монтаже электропроводки мы придерживаемся всех правил и норм. Цена на электромонтаж под ключ может варьироваться в зависимости от объема работ и сложности проекта. '
          paragraph3='Мы предлагаем конкурентные цены и гибкую систему скидок. Вы можете рассчитывать на прозрачность и честность в наших расчетах.'
          paragraph4='Мы работаем только с проверенными поставщиками, чтобы обеспечить надежность и долговечность вашей электропроводки.'
          pic1='collage__electricity-pic1'
          pic2='collage__electricity-pic2'
          pic3='collage__electricity-pic3'
          pic4='collage__electricity-pic4'
          pic5='collage__electricity-pic5'
        />
        <Footer />
      </section>
  );
}

export default Electricity;