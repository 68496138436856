import { QuizCardWrapper } from "../../QuizCardWrapper/QuizCardWrapper";
import LengthKitchen1 from "../../../../../images/quiz_img/LengthKitchen1.webp";
import LengthKitchen2 from "../../../../../images/quiz_img/LengthKitchen2.webp";
import LengthKitchen3 from "../../../../../images/quiz_img/LengthKitchen3.webp";

export const Step4 = ({ onClick }) => {
  const data = [
    {
      image: LengthKitchen1,
      title: "До 3 метров",
    },
    {
      image: LengthKitchen2,
      title: "От 3 до 6 метров",
    },
    {
      image: LengthKitchen3,
      title: "Более 6 метров",
    },
  ];

  const onCardClick = (data) => {
    onClick(data);
  };

  return (
    <QuizCardWrapper cardData={data} onClick={onCardClick}></QuizCardWrapper>
  );
};