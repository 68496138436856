import React from "react";
import {
  CheckboxContainer,
  HiddenCheckbox,
  Icon,
  StyledCheckbox,
} from "./styles";

export const Checkbox = ({ onClick, checked, large, ...props }) => (
  <CheckboxContainer onClick={onClick}>
    <HiddenCheckbox checked={checked} {...props} readOnly />
    <StyledCheckbox large={large} checked={checked}>
      <Icon viewBox="0 0 24 24"></Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);
