import defaultBannerImg from "../../../../images/kitchen4.jpg";
import { BannerContainer, BannerHeader, BannerImage } from "./styles";
import { Container } from "@material-ui/core"; //TODO неебу что это тоже за нахуй

export const BannerComponent = ({ bannerUrl, children }) => {
  return (
    <BannerContainer>
      <BannerHeader />
      <BannerImage imageSrc={bannerUrl || defaultBannerImg}>
        <Container>{children}</Container>
      </BannerImage>
    </BannerContainer>
  );
};