import Kitchens from "../Pages/Kitchens/Kitchens";
import History from "../Pages/History/History";
import Main from "../Pages/Main/Main";
import { Route, Routes, useLocation } from "react-router-dom";
import Puzzles from "../Pages/Puzzles/Puzzles";
import CardInfo from "../CardInfo/CardInfo";
import { getChairsData, getDecor, getFurniture, getKids, getPilowsData, getPuzzles, postFeedback, postPurchase } from '../../utils/Api';
import Catalog from "../Pages/Catalog/Catalog";
import Children from "../Pages/Children/Children";
import Furniture from "../Pages/Furniture/Furniture";
import Houses from "../Pages/Houses/Houses";
import Decor from "../Pages/Decor/Decor";
import ForBuyers from "../Pages/ForBuyers/ForBuyers";
import Contacts from "../Pages/Contacts/Contacts";
import Faq from "../Pages/Faq/Faq";
import Policy from "../Pages/Policy/Policy";
import Cart from "../Pages/Cart/Cart";
import cart from "../../utils/CartCore";
import NotFound from "../Pages/NotFound/NotFound";
import { useEffect, useState } from "react";
import Gallery from "../Pages/Gallery/Gallery";
import { CartTotalCountContext } from "../../contexts/CartTotalCountContext"
import CallMePopup from "../CallMePopup/CallMePopup";
import ImagePopup from "../ImagePopup/ImagePopup";
import QuizPage from "../Pages/QuizPage/QuizPage"
import QuizExit from "../Pages/QuizExit/QuizExit";
import Electricity from "../Pages/Electricity/Electricity";
import LowCurrent from "../Pages/LowCurrent/LowCurrent";


function App() {
  const [isCallMePopupOpen, setIsCallMePopupOpen] = useState(false);
  const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});
  const [furniture, setFurniture] = useState([]);
  const [kids, setKids] = useState([]);
  const [decor, setDecor] = useState([]);
  const [puzzles, setPuzzles] = useState([]);
  const [chairs, setChairs] = useState([]);
  const [pillows, setPillows] = useState([]);
  const location = useLocation();


  useEffect(() => {
    window.ym(94659684, 'hit', window.location.href);
  }, [location.pathname])

  useEffect(() => {
    getChairsData()
      .then((chairs) => {
        setChairs(chairs);
      })
      .catch(err => console.log(err));

    getPilowsData()
      .then((pillows) => {
        setPillows(pillows);
      })
      .catch(err => console.log(err));

    getFurniture()
      .then((furnitureData) => {
        setFurniture(furnitureData);
      })
      .catch(err => console.log(err));

    getDecor()
      .then((decorData) => {
        setDecor(decorData);
      })
      .catch(err => console.log(err));

    getKids()
      .then((kidsData) => {
        setKids(kidsData);
      })
      .catch(err => console.log(err));

    getPuzzles()
      .then((puzzlesData) => {
        setPuzzles(puzzlesData);
      })
      .catch(err => console.log(err));
  }, [])



  const handleSendCartData = (data) => {
    return postPurchase(data)
  }

  const handleSubmitContactForm = (data) => {
    return postFeedback(data)
  }


  const handleCardClick = (card) => {
    console.log("карточка товара", selectedCard);
    setSelectedCard(card);
    setIsImagePopupOpen(true);
  }

  const closeAllPopups = () => {
    setIsCallMePopupOpen(false);
    setIsImagePopupOpen(false);
    setSelectedCard({});
  }

  const handleCallMePopupClick = () => {
    setIsCallMePopupOpen(true);
  }

  const [totalCartItemCount, setTotalCartItemCount] = useState(cart.getTotalCount());

  const setCartItemCount = (productId, sizeId, count) => {
    cart.set(productId, sizeId, count);
    setTotalCartItemCount(cart.getTotalCount());
  }

  const handleViewOnTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  return (
    <CartTotalCountContext.Provider value={[totalCartItemCount, setTotalCartItemCount]}>
      <div className="app">
        <div className="page">
          <Routes>
            <Route path="/" element={
              <Main
                handleViewOnTop={handleViewOnTop}
                onCallMe={handleCallMePopupClick}
                setCartItemCount={setCartItemCount}
                puzzles={puzzles}
                decor={decor}
              />} />
            <Route path="/kitchens" element={
              <Kitchens
                onCallMe={handleCallMePopupClick}
              />}
            />
            <Route path="/history" element={
              <History
                onCallMe={handleCallMePopupClick}
              />}
            />
            <Route path="/puzzles" element={
              <Puzzles
                onCallMe={handleCallMePopupClick}
                puzzles={puzzles}
                setCartItemCount={setCartItemCount}
              />}
            />
            <Route path="/quiz" element={
              <QuizPage />
            }
            />
            <Route path="/electricity" element={
              <Electricity
                onCallMe={handleCallMePopupClick}
              />}
            />
            <Route path="/low-current" element={
              <LowCurrent
                onCallMe={handleCallMePopupClick}
              />}
            />
            <Route path="/card-info/:id" element={
              <CardInfo
                setCartItemCount={setCartItemCount}
                onCardClick={handleCardClick}
                onCallMe={handleCallMePopupClick}
                // getCardInfo={getCardInfo}
                puzzles={puzzles}
              />} />
            <Route path="/catalog" element={
              <Catalog
                handleViewOnTop={handleViewOnTop}
                onCallMe={handleCallMePopupClick}
              />}
            />
            <Route path="/children" element={
              <Children
                onCallMe={handleCallMePopupClick}
                children={chairs}
                relatedProducts={pillows}
                setCartItemCount={setCartItemCount}
              />
            } />
            <Route path="/gallery" element={
              <Gallery
                onCallMe={handleCallMePopupClick}
              />}
            />
            <Route path="/furniture" element={
              <Furniture
                onCallMe={handleCallMePopupClick}
                furniture={furniture}
                children={kids}
                setCartItemCount={setCartItemCount}
              />} />
            <Route path="/houses" element={
              <Houses
                onCallMe={handleCallMePopupClick}
              />}
            />
            <Route path="/decor" element={
              <Decor
                onCallMe={handleCallMePopupClick}
                decor={decor}
                setCartItemCount={setCartItemCount}
              />}
            />
            <Route path="/for-buyers" element={
              <ForBuyers
                onCallMe={handleCallMePopupClick}
              />}
            />
            <Route path="/contacts" element={
              <Contacts
                onSubmitContactForm={handleSubmitContactForm}
                onCallMe={handleCallMePopupClick}
              />}
            />
            <Route path="/faq" element={<Faq />} />
            <Route path="/quiz-exit" element={<QuizExit />} />
            <Route path="/policy" element={
              <Policy
                onCallMe={handleCallMePopupClick}
              />}
            />
            <Route path="/cart" element={
              <Cart
                onSubmitCartForm={handleSendCartData}
                onCallMe={handleCallMePopupClick}
                // getCardInfo={getCardInfo}
                // children={kids}
                setCartItemCount={setCartItemCount}
              />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
      <CallMePopup
        isOpen={isCallMePopupOpen}
        onClose={closeAllPopups}
        onSubmitCallMe={closeAllPopups}
      />
      <ImagePopup
        isOpen={isImagePopupOpen}
        onClose={(closeAllPopups)}
        selectedCard={selectedCard}
      />
    </CartTotalCountContext.Provider>

  );
}

export default App;