
function Stage(props) {
  return (
      <section className="stage">
        <h3 className="stage__title">{props.title}</h3>
        <p className="stage__text">{props.text}</p>
        <div className="stage__image-container">
          <img className="stage__image"alt='Изображение' src={props.img1}></img>
          <img className="stage__image"alt='Изображение' src={props.img2}></img>
          <img className="stage__image"alt='Изображение' src={props.img3}></img>
          <img className="stage__image"alt='Изображение' src={props.img4}></img>
        </div>
      </section>
  );
}

export default Stage;