
function KidsText() {
  return (
      <section className="kids-text">
        <div className="card-info__text-container">
          <h3 className="card-info__text-title">Детский растущий стул BARKWOOD из натурального массива кавказского дуба</h3>
          <p className="card-info__text-description">Дуб как известно, славится своей прочностью, красивой текстурой дерева и гипоаллергенностью. 
            Стул покрыт натуральным маслом с твердым воском, что обеспечивает долговечность цвета и водостойкость.
          </p>
          <p className="card-info__text-description">Растущий стул BARKWOOD – воплощение любых желаний родителей и детей. 
            Он незаменим при кормлении, ведь ребенок находится за общим столом с родителями, на удобной высоте. 
            По системе Монтессори, на ребенка это сказывается положительным образом. Он чувствует себя взрослым членом семьи.
          </p>
          <p className="card-info__text-description">
            Благодаря специальной конструкции стула, ваш ребенок научится контролировать осанку. 
            Школьник во время учебы не будет подвержен быстрой утомляемости, ведь его спина будет ровная. 
            Стул имеет европейскую сертификацию качества и соответствия
          </p>
        </div>
      </section>
  );
}

export default KidsText;