import { QuizCardWrapper } from "../../QuizCardWrapper/QuizCardWrapper";
import Image1 from "../../../../../images/quiz_img/quiz_1_color_loft.webp";
import Image2 from "../../../../../images/quiz_img/quiz_1_color_minimalism.webp";
import Image3 from "../../../../../images/quiz_img/quiz_1_color_scandinavian.webp";
import Image4 from "../../../../../images/quiz_img/quiz_1_color_neomodern.webp";
import Image5 from "../../../../../images/quiz_img/quiz_1_color_classic.webp";
import Image6 from "../../../../../images/quiz_img/quiz_1_color_provence.webp";

export const StepO = ({ onClick }) => {
  const cardData = [
    {
      image: Image1,
      title: "Лофт",
    },
    {
      image: Image2,
      title: "Минимализм",
    },
    {
      image: Image3,
      title: "Скандинавский",
    },
    {
      image: Image4,
      title: "Неомодерн",
    },
    {
      image: Image5,
      title: "Классика",
    },
    {
      image: Image6,
      title: "Прованс",
    },
  ];

  const handleCardClick = (data) => onClick(data);

  return <QuizCardWrapper cardData={cardData} onClick={handleCardClick} />;
};
