import TextField from "@material-ui/core/TextField"; // TODO хер пойми что это такое
import { palette } from "../palette";
import styled from "styled-components";

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 10px;
  font-family: "Commissioner";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.1rem;
`;

export const StyledInput = styled(TextField)`
  label {
    color: #94a389 !important;
  }
  .MuiFilledInput-underline:after {
    border-bottom: 2px solid #94a389;
  }
  .MuiInput-underline:after {
    border-bottom-color: ${palette.colors.txt_gray};
  }

  &.Mui-focused {
    label {
      color: #94a389;
    }

    .MuiInput-underline:before {
      border-bottom-color: #b7ce96;
    }
  }

  .MuiInput-input {
    color: ${palette.colors.txt_gray};
    background-color: transparent;
  }
  .MuiFilledInput-root {
    background: ${(props) =>
    props.$hasValue ? "#EEEEEE" : "transparent"} !important
    ;
  }
`;
