import { useState } from "react";
import Hovered from "../Hovered/Hovered";
import Navigation from "../Navigation/Navigation";


function Header({onCallMe}) {
  const [isBurgerMenuOpened, setIsBurgerMenuOpened] = useState(false);

  const handleBurgerOpen = () => {
    setIsBurgerMenuOpened(true);
  };

  const handleBurgerClose = () => {
    setIsBurgerMenuOpened(false);
  };

  return (
    <header className="header">
      <Navigation
        isBurgerMenuOpened={isBurgerMenuOpened}
        handleBurgerClose={handleBurgerClose}
        onCallMe={onCallMe}/>
      <Hovered>
        <button className={`header__burger-button${isBurgerMenuOpened ? " header__burger-button_type-hidden" : ""}`}
          onClick={handleBurgerOpen}></button>
      </Hovered>
    </header>
  );
}

export default Header;
