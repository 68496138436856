class CartCore {

    constructor() {
      this._cachedCount = null;
    }

  getCurrentItems() {

    if (this._cachedItems) {
      console.log("got cachedItems");
      return this._cachedItems;
    }
    const localStorageString = localStorage.getItem('cart_items');

    if (!localStorageString) {
      console.log("got no cards in local Storage");
      this._cachedItems = new Map();
    } else {
      function reviver(key, value) {
        if (typeof value === 'object' && value !== null) {
          if (value.dataType === 'Map') {
            return new Map(value.value);
          }
        }
        return value;
      }

      const currentItems = JSON.parse(localStorageString, reviver);

      // console.log(currentItems);
      this._cachedItems = currentItems;
    }
    return this._cachedItems;
  }

  getTotalCount() {
    if (this._cachedCount != null) {
      // console.log("взяли кол-во предметов из кэша");
      return this._cachedCount;
    }
    
    let counter = 0;
    this.getCurrentItems().forEach((itemInCart, productId) => {
      //console.log(`cartCore cache. id: ${productId}, itemInCart: ${itemInCart}`);

      itemInCart.forEach((count, sizeId) => {
        // console.log(`cartCore cache. sizeId: ${sizeId}, count: ${count}`);
        counter += count;
      });
    });
    this._cachedCount = counter;
    return counter;
  }
  

  has(productId, sizeId) {
    const currentItems = this.getCurrentItems();

    return currentItems.has(productId) && currentItems.get(productId).has(sizeId);
  }

  set(productId, sizeId, count) {
    if (count === 0) {
      this.removeAll(productId, sizeId);
      return;
    }
    const currentItems = this.getCurrentItems();
    // console.log("currentItems");
    // console.log(currentItems);
    const itemInCart = currentItems.has(productId) ? currentItems.get(productId) : new Map();
    let prevCount = itemInCart.has(sizeId) ? itemInCart.get(sizeId) : 0;
    this._cachedCount += (count - prevCount);
    itemInCart.set(sizeId, count);
    // console.log(itemInCart);

    currentItems.set(productId, itemInCart);
    this.__saveCurrentItems(currentItems);
  }

  removeAll(productId, sizeId) {
    const currentItems = this.getCurrentItems();

    if (currentItems.has(productId) && currentItems.get(productId).has(sizeId)) {
      this._cachedCount -= currentItems.get(productId).get(sizeId);
      currentItems.get(productId).delete(sizeId);
      if (currentItems.get(productId).size === 0) {
        currentItems.delete(productId);
        console.log("deleted whole product from cart");
      }
      this.__saveCurrentItems(currentItems);
    }
  }


  __saveCurrentItems(currentItems) {
    this._cachedItems = currentItems;
    
    console.log("Saved currentItemsToCache:");

    console.log(currentItems);

    function replacer(key, value) {
      if (value instanceof Map) {
        return {
          dataType: 'Map',
          value: Array.from(value.entries()), // or with spread: value: [...value]
        };
      } else {
        return value;
      }
    }

    const str = JSON.stringify(currentItems, replacer);

    // console.log("str- ", str);

    // const result = JSON.stringify(Array.from(currentItems),
    //   (key, value) => {
    //     console.log(key);
    //     console.log(value);

    //     return {
    //       dataType: 'Map',
    //       value: Array.from(value.entries()), // or with spread: value: [...value]
    //     };
    //   });

    // console.log("cartCore shows-", result);
    localStorage.setItem('cart_items', str);
  }
}

const cart = new CartCore();

export default cart;



