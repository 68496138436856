import KidsText from "./KidsText/KidsText";
import PillowText from "./PillowText/PillowText";
import PuzzleText from "./PuzzleText/PuzzleText";
import DecorText from "./DecorText/DecorText";
import FurnitureText from "./FurnitureText/FurnitureText";

function Description({productType, productCollection}) {
  return (
      <section className="description">
        {productType === 'kids' && productCollection === 'Стулья' ? <KidsText /> : ''}
        {productType === 'kids' && productCollection === 'Подушки' ? <PillowText /> : ''}
        {productType === 'puzzle' ? <PuzzleText /> : ''}
        {productType === 'decor' ? <DecorText /> : ''}
        {productType === 'furniture' ? <FurnitureText /> : ''}
      </section>
  );
}

export default Description;