import { QuizCardWrapper } from "../../QuizCardWrapper/QuizCardWrapper";

import Image1 from "../../../../../images/quiz_img/quiz_3_cover_glas.webp";
import Image2 from "../../../../../images/quiz_img/quiz_3_cover_mate.webp";

export const Step2 = ({ onClick }) => {
  const data = [
    {
      image: Image1,
      title: "Глянцевые фасады",
    },
    {
      image: Image2,
      title: "Матовые и суперматовые",
    },
  ];

  const onCardClick = (data) => {
    onClick(data);
  };
  return <QuizCardWrapper count="3" cardData={data} onClick={onCardClick} />;
};
