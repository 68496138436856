import Logo404 from "../../../images/logo404.png";
import Hovered from "../../Hovered/Hovered";
import { useNavigate } from "react-router-dom";

function QuizExit() {
  const navigate = useNavigate();

  const handleGoToHomePage = () => {
    navigate('/');
  }

  return (
    <section className="quiz-exit">
      <img className="quiz-exit__logo" src={Logo404} alt='Логотип'></img>
      <h2 className="quiz-exit__title">Заявка оформлена !</h2>
      <p className="quiz-exit__text">Благодарим за обращение в компанию Barkwood.
        Мы уже обрабатываем вашу заявку.
        В ближайшее время с вами свяжется наш менеджер</p>
      {/* <p className="quiz-exit__text">В ближайшее время с вами свяжется наш менеджер</p> */}
      {/* <p className="quiz-exit__text">С надеждой на сотрудничество, Barkwood</p> */}
      <Hovered>
        <button className="quiz-exit__button" onClick={handleGoToHomePage}>на главную</button>
      </Hovered>
    </section>
  )
};
export default QuizExit;