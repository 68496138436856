import { useState } from "react";
import { useFormWithValidation } from "../../hooks/useFormWithValidation";
// import { INPUT_ERRORS } from "../../utils/constants";
// import RequestError from "../RequestError/RequestError";
import PopupWithForm from "../PopupWithForm/PopupWithForm";
import { postFeedback } from "../../utils/Api";
import RequestError from "../RequestError/RequestError";
import { INPUT_ERRORS } from "../../utils/constants";


function CallMePopup({isOpen, onClose, popupType, onSubmitCallMe}) {
  const [submitButtonText, setSubmitButtonText] = useState("Отправить");
  const [requestError, setRequestError] = useState("");
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const { values, handleInputChange, errors, isValid } =
    useFormWithValidation();


    const handleSubmit = (e) => {
      e.preventDefault();
      setIsFormDisabled(true);
      setSubmitButtonText("Отправляем...");
      postFeedback(values)
        .then(() => {
          console.log(values);
          setSubmitButtonText("Отправлено");
          onSubmitCallMe();
        })
        .catch((err) => {
          setRequestError(err);
          setIsFormDisabled(false);
          setSubmitButtonText("Отправить");
          console.log(err);
        });
    };
  

  return (
    <PopupWithForm
      popupType="call-me"
      popupTitle="Введите данные"
      saveButtonText="Отправить"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      submitButtonText={submitButtonText}
      handleInputChange={handleInputChange}
      isValid={isValid}
      isFormDisabled={isFormDisabled}
    >
      <label className="popup__form-field">
        <input
          className="popup__input"
          type="text"
          name="name"
          id="name"
          placeholder="Имя"
          required
          minLength="2"
          maxLength="40"
          value={values.name || ""}
          onChange={handleInputChange}
        />
        <span className="popup__input-error" id="name-error">{errors.name ? INPUT_ERRORS.WRONG_NAME : ""}</span>
      </label>
      <label className="popup__form-field">
        <input
          className="popup__input"
          placeholder="+7 (999) 123-45-67"
          id="phone"
          type="tel"
          value={values.phone || ""}
          onChange={handleInputChange}
          name="phone"
          minLength="10"
          maxLength="18"
          disabled={isFormDisabled}
          required
        />
        <span className="popup__input-error">{errors.email ? INPUT_ERRORS.WRONG_PHONE : ""}</span>
      </label>
      <RequestError
        className="popup-form__request-error"
        errorText={requestError}
      />
    </PopupWithForm>
  )
}

export default CallMePopup;
