import Banner from "../../Banner/Banner";
import Footer from "../../Footer/Footer";
import Hovered from "../../Hovered/Hovered";
import FilterButton from "../../FilterButton/FilterButton"

function Houses({onCallMe}) {
  return (
      <section className="houses">
        <Banner
          onCallMe={onCallMe}
          title='BARKWOOD'
          subtitle='Строительство домов'
          buttonText='Заказать расчет'
          className='banner__houses'
        />
        <div className="houses__filter">
          <h2 className="houses__filter-title">Наши преимущества:</h2>
          <div className="houses__advantages">
            <button className="houses__filter-button">Современное производство</button>
            <button className="houses__filter-button">Монтаж и сборка</button>
            <button className="houses__filter-button">Утепление дома</button>
            <button className="houses__filter-button">Экологичность</button>
            <button className="houses__filter-button">Качественный результат</button>
          </div>
            {/* <FilterButton title='Современное производство'/>
            <FilterButton title='Монтаж и сборка'/>
            <FilterButton title='Утепление дома'/>
            <FilterButton title='Экологичность'/>
            <FilterButton title='Качественный результат'/> */}
          </div>
        <div className="houses__gallery-items">
        <Hovered>
          <div className="houses__gallery-item houses__gallery-rubric">
            <p className="houses__gallery-text">
              Строительство домов в стиле Барнхаус - это уникальное сочетание классической элегантности и современной функциональности.
            </p>
            <p className="houses__gallery-text">
              Барнхаус вдохновлен американской сельской архитектурой. Такой стиль придает дому особый шарм и индивидуальность.
              Барнхаус - это большие открытые пространства, высокие потолки и использование натуральных материалов.
            </p>
            <p className="houses__gallery-text">
              Стиль барнхаус призван создать атмосферу комфорта и гармонии с окружающей природой.
            </p>
            <p className="houses__gallery-text">
              Большие окна позволяют максимально использовать естественный свет, а также наслаждаться панорамными видами на окружающий ландшафт. 
              Барнхаус обычно включает в себя просторную гостиную, кухню с островом и современные удобства, которые сочетаются 
              с элементами старинной архитектуры.
            </p>
          </div>  
        </Hovered>
        <Hovered>
          <div className="houses__gallery-item houses__gallery-pic1"></div>
        </Hovered>
        <Hovered>
          <div className="houses__gallery-item houses__gallery-pic2"></div>
        </Hovered>
        <Hovered>
          <div className="houses__gallery-item houses__gallery-pic3"></div>
        </Hovered>
        <Hovered>
          <div className="houses__gallery-item houses__gallery-pic4"></div>
        </Hovered>
        <Hovered>
          <div className="houses__gallery-item houses__gallery-pic5"></div>
        </Hovered>
      </div>
      <p className="houses__temp-text">Данный раздел еще в разработке. Скоро здесь появится дополнительная информация.</p>
        <Footer />
      </section>
  );
}

export default Houses;