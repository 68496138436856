import { Link } from "react-router-dom";
// import cart from "../../utils/CartCore";


function Card({ properties, setCartItemCount }) {
  const price = properties.sizes[0].price.toLocaleString();
  // const isInCart = cart.has(properties.id);
  // console.log(properties);

  // const setOneItemToCard = () => {
  //   setCartItemCount(properties.productId, properties.sizes[0]._id, 1);
  // }

  return (
    <section className="card">
      <Link to={`/card-info/${properties.productId}`}>
        <img className='card__image' alt='Изображение товара' src={properties.sizes[0].images[0]}></img>
      </Link>
      <div className='card__details'>
        <h2 className='card__title'>{properties.name}</h2>
        <p className='card__code'>Артикул: {properties.sizes[0].sizeArticle}</p>
        <p className='card__price'>{price} руб.</p>
        <div className='card__buttons'>
          <Link to={`/card-info/${properties.productId}`}>
            <button className='card__details-button'>Подробнее</button>
          </Link>
          {/* <button className='card__buy-button' onClick={setOneItemToCard}>В корзину</button> */}
        </div>
      </div>
    </section>
  );
}

export default Card;