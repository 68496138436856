import { ButtonComponent } from "../../ButtonComponent/ButtonComponent";
import { Checkbox } from "../../CheckboxComponent/CheckboxComponent";
import { useState } from "react";
import { palette } from "../../palette";
import styled from "styled-components";

const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 3rem 0;
  width: 100%;

   @media screen and (max-width: 780px) {
    flex-direction: column;
    gap: 1rem;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const Option = styled.span`
  display: flex;
  gap: 1.3rem;
  align-items: center;
  cursor: pointer;
`;
const Label = styled.p`
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${palette.colors.txt_gray};
  margin: 0;
`;

const StepContainer = styled.div`
  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Step7 = ({ onClick }) => {
  const [selected, setSelected] = useState([]);

  const isChecked = (item) => {
    return selected.findIndex((selectedCheck) => selectedCheck === item) !== -1;
  };

  const onBtnClick = () => {
    onClick(selected);
  };

  const data = [
    "Вытяжка",
    "Раковина + смеситель",
    "Посудомоечная машина",
    "Варочная поверхность",
    "Духовой шкаф",
    "Микроволновая печь",
    "Кофемашина",
    "Холодильник",
    "Стиральная машина",
    "Винный шкаф",
  ];

  const onCheckboxClick = (item) => {
    const checkedIndex = selected.findIndex(
      (selectedCheck) => selectedCheck === item,
    );
    const arr = [...selected];

    if (checkedIndex !== -1) {
      arr.splice(checkedIndex, 1);
      setSelected(arr);
    } else {
      arr.push(item);
      setSelected(arr);
    }
  };

  return (
    <StepContainer>
      <OptionsContainer>
        <Column>
          {data.slice(0, data.length / 2).map((item, index) => (
            <Option
              key={index}
              onClick={() => {
                onCheckboxClick(item);
              }}
            >
              <Checkbox large checked={isChecked(item)} />
              <Label>{item}</Label>
            </Option>
          ))}
        </Column>

        <Column>
          {data.slice(data.length / 2).map((item, index) => (
            <Option
              key={index}
              onClick={() => {
                onCheckboxClick(item);
              }}
            >
              <Checkbox large checked={isChecked(item)} />
              <Label>{item}</Label>
            </Option>
          ))}
        </Column>
      </OptionsContainer>
      <ButtonComponent
        styles={{ width: "fit-content", padding: "2rem 3rem" }}
        onBtnClick={onBtnClick}
      >
        Далее
      </ButtonComponent>
    </StepContainer>
  );
};