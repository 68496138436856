import close_icon from '../../../../images/close_icon.svg';

function FaqGroup({ blockData }) {
  const qa = blockData.qa;

  return (
    <div className="faq-group">
      <h3 className="faq-group__title">{blockData.name}</h3>
      {
        qa.map((dialog) => {
          return (
            <div className="faq-group__block" key={dialog.id}>
              <p className="faq-group__question">{dialog.question}</p>
              <p className="faq-group__answer">{dialog.answer}</p>
              <button className="faq-group__btn">
                <img className="faq-group__btn-img" src={close_icon} alt="иконка закрытия" />
              </button>
            </div>
          )
        })
      }
    </div>
  )
};

export default FaqGroup;