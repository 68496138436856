import { useState } from "react";
import { InputComponent } from "../InputComponent/InputComponent";
import { ButtonComponent } from "../ButtonComponent/ButtonComponent";
import { FieldSet, FormControl, FormTitle, Label, TextLink } from "./styles";
import { Checkbox } from "../CheckboxComponent/CheckboxComponent";
import { FormWrapper } from "../FormWrapper/FormWrapper";
import ReactInputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { postBxDeal } from "../../../../utils/Api";

export const ContactForm = ({ data }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    const errors = {};

    if (!name) {
      errors.name = "Пожалуйста, введите ваше имя";
    }

    if (!phone || phone.replace(/\s/g, "").length !== 12) {
      errors.phone = "Пожалуйста, введите номер телефона";
    }

    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    postBxDeal({ name, phone, quizData: data })
      .then(() => {
        console.log("thats ok u made it")
      })
      .catch(err => console.error(err));

    navigate("/quiz-exit", { state: { isSendFormQuiz: true } });
  };

  const handleInputChange = (fieldName, value) => {
    switch (fieldName) {
      case "name":
        setName(value);
        break;
      case "phone":
        setPhone(value);
        break;
      default:
        break;
    }
  };

  return (
    <FormWrapper>
      <FormTitle>Получить расчет стоимости</FormTitle>
      <FieldSet style={{ outline: "none", border: "none" }}>
        <InputComponent
          label="Имя"
          value={name}
          onChange={(value) => handleInputChange("name", value)}
          error={!!errors.name}
          helperText={errors.name}
        />
        <ReactInputMask
          mask="+7 999 999 99 99"
          value={phone}
          disabled={false}
          maskChar=" "
          onChange={(value) => handleInputChange("phone", value)}
        >
          {() => (
            <InputComponent
              label="Номер телефона"
              value={phone}
              error={!!errors.phone}
              helperText={errors.phone}
            />
          )}
        </ReactInputMask>
      </FieldSet>
      <FormControl>
        <Checkbox
          checked={isChecked}
          onClick={() => setIsChecked(!isChecked)}
        />
        <Label onClick={() => setIsChecked(!isChecked)}>
          Я согласен (-на) на обработку персональных данных в соответствии с
          указанным &nbsp;<TextLink to="/policy">здесь</TextLink>&nbsp;текстом.
        </Label>
      </FormControl>
      <ButtonComponent disabled={!isChecked} onBtnClick={handleSubmit} onClick={() => { window.ym(94659684, 'reachGoal', 'btn-click-quiz') }}>
        Отправить
      </ButtonComponent>
    </FormWrapper>
  );
};
