import { Link } from "react-router-dom";
import Hovered from "../Hovered/Hovered";
import Logo from "../Logo/Logo";
import Whattsapp from "../../images/whatsapp.svg";
import Instagram from "../../images/instagram.svg";
import Vk from "../../images/vk.svg";
import Telegram from "../../images/footer__telegram.svg";
import Ok from "../../images/ok.svg";

function Footer() {
  return (
    <section className="footer">
      <div className="footer__logo-container">
        <Logo />
        <p className="footer__logo-name">BARKWOOD</p>

      </div>
      <div className='footer__menu'>
        <div className='footer__items'>
          <div className='footer__title'>Реквизиты</div>
          <p className='footer__item'>ИП Наконечнюк Иван</p>
          <p className='footer__item'>ОГРН: 322774600741691</p>
          <p className='footer__item'>ИНН: 672403426727</p>
          <p className='footer__item'>г.Москва,  улица Адмирала Руднева, 4</p>
        </div>
        <div className='footer__items'>
          <div className='footer__title'>Каталог</div>
          <Hovered>
            <Link to="/puzzles" className="footer__item">
              Деревянные пазлы
            </Link>
          </Hovered>
          <Hovered>
            <Link to="/furniture" className="footer__item">
              Мебель под заказ
            </Link>
          </Hovered>
          <Hovered>
            <Link to="/kitchens" className="footer__item">
              Кухни под заказ
            </Link>
          </Hovered>
          <Hovered>
            <Link to="/children" className="footer__item">
              Товары для детей
            </Link>
          </Hovered>
          <Hovered>
            <Link to="/houses" className="footer__item">
              Строительство домов
            </Link>
          </Hovered>
          <Hovered>
            <Link to="/decor" className="footer__item">
              Предметы декора
            </Link>
          </Hovered>
        </div>
        <div className='footer__items'>
          <div className='footer__title'>Покупателям</div>
          <Hovered>
            <Link to="/history" className="footer__item">
              О компании
            </Link>
          </Hovered>
          <Hovered>
            <Link to="/" className="footer__item">
              Новинки в магазинах
            </Link>
          </Hovered>
          <Hovered>
            <Link to="/faq" className="footer__item">
              Ответы на вопросы
            </Link>
          </Hovered>
          <Hovered>
            <Link to="/comments" className="footer__item">
              Отзывы покупателей
            </Link>
          </Hovered>
          <Hovered>
            <Link to="/for-buyers" className="footer__item">
              Для покупателя
            </Link>
          </Hovered>
          <Hovered>
            <Link to="/policy" className="footer__item">
              Политика конфиденциальности
            </Link>
          </Hovered>
        </div>
        <div className='footer__contacts'>
          <div className='footer__title'>Контакты</div>
          <p className='footer__contacts-item'>+7 (495) 97 97 97 7</p>
          <p className='footer__contacts-item'>Поможем с выбором</p>
          <p className='footer__contacts-item'>support@barkwood.ru</p>
          <div className="footer__social">
            <a href="https://api.whatsapp.com/send/?phone=89916487291" target="_blank" rel="noreferrer">
              <img className="footer__social-icon" src={Whattsapp} alt='Ватсап'></img>
            </a>

            <img className="footer__social-icon" src={Instagram} alt='Инстаграм'></img>

            <a href="https://vk.com/barkwood.puzzles" target="_blank" rel="noreferrer">
              <img className="footer__social-icon" src={Vk} alt='Вконтакте'></img>
            </a>

            <a href="https://t.me/+79916487291" target="_blank" rel="noreferrer">
              <img className="footer__social-icon" src={Telegram} alt='Телеграм'></img>
            </a>

            <a href="https://ok.ru/group/70000003371761" target="_blank" rel="noreferrer">
              <img className="footer__social-icon" src={Ok} alt='Одноклассники'></img>
            </a>

          </div>
        </div>
      </div>
      <div className='footer__underline'></div>
    </section>
  );
}

export default Footer;