import minus from "../../images/cart_minus.png";
import plus from "../../images/cart_plus.png";
import Delete from "../../images/cart_delete.png";
import { useState } from "react";

function CartItem({ properties, updateCartItemCount, removeItem }) {
  

  const [currentCount, setCurrentCount] = useState(properties.count);

  const setNewCount = (oldCount, newCount) => {
    updateCartItemCount(properties.productId, properties.cardInfo._id, newCount, (newCount - oldCount) * Number(properties.cardInfo.price));
    setCurrentCount(newCount);
    if (newCount <= 0) {
      removeItem(properties.productId, properties.cardInfo._id);
    }
  }

  const decrementCount = () => {
    setNewCount(currentCount, currentCount - 1);
  }

  const incrementCount = () => {
    setNewCount(currentCount, currentCount + 1);
  }

  const remove = () => {
    setNewCount(currentCount, 0);
  }

  return (
    <div className="cart__item">
      <img className="cart__image" src={properties.cardInfo.images[0]} alt='Изображение товара'></img>
      <div className="cart__info">
        <h2 className="cart__info-title">{properties.name}</h2>
        <p className="cart__info-code">{properties.cardInfo.sizeArticle}</p>
        <p className="cart__info-size">Размер: {properties.cardInfo.size}</p>
      </div>
      <div className="cart__item-quantity-container">
        <img className="cart__item-minus" src={minus} alt='вычесть один товар' onClick={decrementCount}></img>
        <div className="cart__item-quantity">{currentCount}</div>
        <img className="cart__item-plus" src={plus} alt='добавить один товар' onClick={incrementCount}></img>
      </div>
      <div className="cart__item-price">{(properties.cardInfo.price * currentCount).toLocaleString()}</div>
      <img className="cart__item-delete" src={Delete} alt='удалить товар' onClick={remove}></img>
    </div>
  );
}

export default CartItem;