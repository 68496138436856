// import AlsoSee from "../../AlsoSee/AlsoSee";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import Banner from "../../Banner/Banner";
import CardList from "../../CardList/CardList";
import FilterButton from "../../FilterButton/FilterButton";

function Decor({ decor, setCartItemCount, onCallMe }) {
  return (
    <section className="decor">
      <Banner
        onCallMe={onCallMe}
        title='Cовременные аксессуары'
        subtitle='для вашего дома'
        buttonText='Вернуться в каталог'
        className='banner__decor'
        to='/catalog'
      />
      <div className="decor__container">
        <p className="decor__text">
          В поисках идеального декора для вашего дома? Наша компания предлагает широкий ассортимент уникальных изделий декора,
          которые подчеркнут вашу индивидуальность и создадут уютную атмосферу в вашем жилище. Мы гордимся тем, что предлагаем
          как ручные, сделанные с душой и любовью изделия, так и фабричные, изготовленные на современном оборудовании.
          В каждом изделии мы сочетаем эстетику и функциональность, отражая современные тенденции и инновационные решения.
        </p>
        {/* <div className="decor__filter">
          <FilterButton title='Полочки' />
          <FilterButton title='Разделочные доски' />
          <FilterButton title='Держатели' />
          <FilterButton title='Аксессуары' />
          <FilterButton title='Другое' />
        </div> */}
        <CardList
          cardItems={decor}
          setCartItemCount={setCartItemCount}
        />
      </div>
      {/* <AlsoSee
        cardItems={decor}
      /> */}
      <Footer />
    </section>
  );
}

export default Decor;