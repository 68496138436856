import Header from "../../Header/Header";
// import AlsoSee from "../AlsoSee/AlsoSee";
import Footer from "../../Footer/Footer";
import Hovered from "../../Hovered/Hovered";
import { Link } from "react-router-dom";

function Catalog({onCallMe, handleViewOnTop}) {
  return (
    <section className="catalog">
      <Header
        onCallMe={onCallMe}
      />
      <div className="catalog__banner"></div>
      <h2 className="catalog__title">Каталог</h2>
      <div className="catalog__items">
        <Hovered>
            <Link to='/kitchens' className="catalog__item catalog__kitchens">
              <div className="catalog__text">
                <button className="catalog__item-title" onClick={handleViewOnTop}>BARKWOOD</button>
                <button className="catalog__item-subtitle" onClick={handleViewOnTop}>КУХНИ</button>
              </div>
            </Link>  
          </Hovered>
          <Hovered>
            <Link to='/puzzles' className="catalog__item catalog__puzzles">
              <div className="catalog__text">
                <button className="catalog__item-title" onClick={handleViewOnTop}>BARKWOOD</button>
                <button className="catalog__item-subtitle" onClick={handleViewOnTop}>ПАЗЛЫ</button>
              </div>
            </Link>
          </Hovered>
          <Hovered>
            <Link to='/furniture' className="catalog__item catalog__furniture">
              <div className="catalog__text">
                <button className="catalog__item-title" onClick={handleViewOnTop}>BARKWOOD</button>
                <button className="catalog__item-subtitle" onClick={handleViewOnTop}>МЕБЕЛЬ</button>
              </div>
            </Link>
          </Hovered>
          <Hovered>
            <Link to='/children' className="catalog__item catalog__children">
              <div className="catalog__text">
                <button className="catalog__item-title" onClick={handleViewOnTop}>BARKWOOD</button>
                <button className="catalog__item-subtitle" onClick={handleViewOnTop}>ДЕТЯМ</button>
              </div>
            </Link>
          </Hovered>
          <Hovered>
            <Link to='/houses' className="catalog__item catalog__houses">
              <div className="catalog__text">
                <button className="catalog__item-title" onClick={handleViewOnTop}>BARKWOOD</button>
                <button className="catalog__item-subtitle" onClick={handleViewOnTop}>ДОМА</button>
              </div>
            </Link>
          </Hovered>
          <Hovered>
            <Link to='/decor' className="catalog__item catalog__decor">
              <div className="catalog__text"> 
                <button className="catalog__item-title" onClick={handleViewOnTop}>BARKWOOD</button>
                <button className="catalog__item-subtitle" onClick={handleViewOnTop}>ДЕКОР</button>
              </div>
            </Link>
          </Hovered>
      </div>  
      {/* <AlsoSee /> */}
      <Footer />
    </section>
  );
}

export default Catalog;