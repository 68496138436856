import Card from '../Card/Card';

function CardList({ cardItems, setCartItemCount }) {
  return (
    <section className="card-list">
      {
        cardItems?.map((card) => (
          <Card
            key={card._id}
            properties={card}
            setCartItemCount={setCartItemCount}
          />))
      }
    </section>
  );
}

export default CardList;