import Hovered from "../Hovered/Hovered";

function Collage(props) {
  return (
    <section className="collage">
      <div className="collage__items">
        <Hovered>
            <div className="collage__item collage__rubric">
              <p className="collage__text collage__text-paragraph1">{props.paragraph1}</p>
              <p className="collage__text collage__text-paragraph2">{props.paragraph2}</p>
              <p className="collage__text collage__text-paragraph3">{props.paragraph3}</p>
              <p className="collage__text collage__text-paragraph4">{props.paragraph4}</p>
            </div>  
          </Hovered>
          <Hovered>
            <div className={`collage__item ${props.pic1}`}></div>
          </Hovered>
          <Hovered>
          <div className={`collage__item ${props.pic2}`}></div>
          </Hovered>
          <Hovered>
          <div className={`collage__item ${props.pic3}`}></div>
          </Hovered>
          <Hovered>
          <div className={`collage__item ${props.pic4}`}></div>
          </Hovered>
          <Hovered>
          <div className={`collage__item ${props.pic5}`}></div>
          </Hovered>
      </div>
    </section>
  );
}

export default Collage;