
function PuzzleText() {
  return (
      <section className="puzzle-text">
        <div className="card-info__text-container">
          <h3 className="card-info__text-title">Деревянный фигурный пазл фабричного производства.</h3>
          <p className="card-info__text-description">
            Волшебство начинается с распаковки декоративной коробочки, 
            а деревянные мелкие и крупные детали дивно шуршат. Собирать головоломку это увлекательное развлечение 
            и полезное времяпровождение не только для детей, но и для взрослых.
          </p>
          <p className="card-info__text-description">
            Все элементы вырезаны из натуральных экологичных и безопасных материалов, тем самым они приятные наощупь, 
            тактильно перебирать яркие фигурные животные игрушки в единое панно одно наслаждение. Фигурный деревянный 
            пазл мозаика это больше, чем просто игра головоломка. Занимает и увлекает, развивает мелкую моторику, воображение 
            у детей, внимание и творческое мышление у подростков, помогает переключиться и расслабиться мамам и папам и всем 
            членам семьи. Такая настольная игра давно стала хобби многих любителей развивающих игр, помогает сменить обстановку 
            как хорошая антистресс игрушка.
          </p>
          <p className="card-info__text-description">
            Красочные пазлы из дерева станут отличным полезным сюрпризом на любой праздник. Прекрасную обучающую игрушку развивашку 
            можно использовать подарком на день рождения как для мальчика, так и для девочки. Малыши хоть и не умеют еще собирать, 
            но очень любят разглядывать и трогать приятные красочные фигурки под присмотром родителей. Собранный пазл в точности 
            повторяет силуэт персонажей забавных животных, птиц и причудливых узоров, по окончании сборки получится замечательная 
            картина, которая может стать прекрасным декором интерьера, разместить ее можно в детской комнате или сделать подарок бабушке 
            или дедушке, собранный своими руками.
          </p>
        </div>
      </section>
  );
}

export default PuzzleText;
