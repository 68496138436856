import { useEffect } from "react";

function Popup({ popupType, containerType, children, isOpen, onClose }) {

  useEffect(() => {
    function closeByEscape(evt) {
      if (evt.key === "Escape") {
        onClose();
      }
    }

    if (isOpen) {
      document.addEventListener("keydown", closeByEscape);
      return () => {
        document.removeEventListener("keydown", closeByEscape);
      };
    }
  }, [isOpen]);

  return (
    <div className={`popup popup_type_${popupType}${isOpen && ' popup_opened'}`}>
      <div className={containerType}>
        <button type="button" className="popup__close-button" onClick={onClose}></button>
        {children}
      </div>
    </div>
  )
}

export default Popup;