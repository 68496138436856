import { useEffect, useState } from "react";
import styled, { keyframes, css } from "styled-components";

const fadeAnimation = keyframes`
  0% { opacity: 0; right: -100% }
  5% { opacity: 0.1; right: -95% }
  10% { opacity: 0.1; right: -90% }
  15% { opacity: 0.15; right: -85% }
  20% { opacity: 0.2; right: -80% }
  25% { opacity: 0.25; right: -75% }
  30% { opacity: 0.3; right: -70% }
  35% { opacity: 0.35; right: -65% }
  40% { opacity: 0.4; right: -60% }
  45% { opacity: 0.45; right: -55% }
  50% { opacity: 0.5; right: -50% }
  55% { opacity: 0.55; right: -45% }
  60% { opacity: 0.6; right: -40% }
  65% { opacity: 0.65; right: -35% }
  70% { opacity: 0.7; right: -30% }
  75% { opacity: 0.7; right: -25% }
  80% { opacity: 0.75; right: -20% }
  85% { opacity: 0.8; right: -15% }
  90% { opacity: 0.85; right: -10% }
  95% { opacity: 0.9; right: -5% }
  100% { opacity: 1; right: 0% }
`;

const Wrapper = styled.div`
  padding: 3rem;
  position: relative;
  height: 82%;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1290px) {
    padding: .5rem;
  }

  animation: ${({ isAnimating }) =>
    isAnimating
      ? css`
          ${fadeAnimation} 0.7s forwards
        `
      : "none"};
`;

export const StepTransitionWrapper = ({ step, children }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (step !== 9) {
      setIsAnimating(true);
      const timeout = setTimeout(() => {
        setIsAnimating(false);
      }, 1000); // Длительность анимации в миллисекундах

      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line
  }, [children]);

  return <Wrapper isAnimating={isAnimating}>{children}</Wrapper>;
};
