function ReviewLink({
  link_name,
  link_url,
  img_src,
  disabled_img_src,
  disabled,
}) {

  return (
    <a href={link_url} target="_blank" rel="noreferrer" className="review-link" disabled>
      {
        disabled ?
          <img className="review-link_img" src={disabled_img_src} alt={link_name}></img> :
          <img className="review-link_img" src={img_src} alt={link_name}></img>
      }
    </a>
  )
};
export default ReviewLink;