import Star from "../../../../images/star.svg";
import Woman from "../../../../images/Female.png";
import Man from "../../../../images/Male.png";
import ReviewList from "../../../ReviewList/ReviewList";


function Comments() {

  const comments = [
    {
      name: 'Сергей',
      text: 'Очень большой выбор пазлов, высокое качество. Детальки не бахромятся, плотненько встают в рисунок. Я здесь уже частый покупатель. И отдельное спасибо за обслуживание. Все быстро и не навязчиво.',
    },
    {
      name: 'Юлия П.',
      text: 'Зазывала шкаф в кухню. Жалею, что не заказала весь ансамбль. Ребята - молодцы!',
    },
    {
      name: 'Елена',
      text: 'Купили для дочери стульчик. Я в восторге. Хорошо подошел к интерьеру кухни. Дочке очень нравится.',
    },
  ]

  return (
    <section className="comments">
      <div className='comments__block'>
        <div className="comments__item">
          <div className="comments__userdata">
            <img className="comments__avatar" src={Man} alt='Аватарка'></img>
            <h2 className='comments__name'>{comments[0].name}</h2>
          </div>
          <div className="comments__grade-block">
            <img className="comments__grade" src={Star} alt='Звездочка'></img>
            <img className="comments__grade" src={Star} alt='Звездочка'></img>
            <img className="comments__grade" src={Star} alt='Звездочка'></img>
            <img className="comments__grade" src={Star} alt='Звездочка'></img>
            <img className="comments__grade" src={Star} alt='Звездочка'></img>
          </div>
          <p className='comments__text'>{comments[0].text}</p>
        </div>
        <div className="comments__item">
          <div className="comments__userdata">
            <img className="comments__avatar" src={Woman} alt='Аватарка'></img>
            <h2 className='comments__name'>{comments[1].name}</h2>
          </div>
          <div className="comments__grade-block">
            <img className="comments__grade" src={Star} alt='Звездочка'></img>
            <img className="comments__grade" src={Star} alt='Звездочка'></img>
            <img className="comments__grade" src={Star} alt='Звездочка'></img>
            <img className="comments__grade" src={Star} alt='Звездочка'></img>
            <img className="comments__grade" src={Star} alt='Звездочка'></img>
          </div>
          <p className='comments__text'>{comments[1].text}</p>
        </div>
        <div className="comments__item">
          <div className="comments__userdata">
            <img className="comments__avatar" src={Woman} alt='Аватарка'></img>
            <h2 className='comments__name'>{comments[2].name}</h2>
          </div>
          <div className="comments__grade-block">
            <img className="comments__grade" src={Star} alt='Звездочка'></img>
            <img className="comments__grade" src={Star} alt='Звездочка'></img>
            <img className="comments__grade" src={Star} alt='Звездочка'></img>
            <img className="comments__grade" src={Star} alt='Звездочка'></img>
            <img className="comments__grade" src={Star} alt='Звездочка'></img>
          </div>
          <p className='comments__text'>{comments[2].text}</p>
        </div>
      </div>

      <ReviewList />
      {/* <button className='comments__button'>Перейти ко всем отзывам</button> */}
    </section>
  );
}

export default Comments;