import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useEffect, useState } from "react";
import Card from "../Card/Card";

function Carousel({ cardItems, setCartItemCount }) {
  const [length, setLength] = useState(cardItems.length);
  const [currentIndex, setCurrentIndex] = useState(0);
  const extendedCardItems = [...cardItems, ...cardItems, ...cardItems];

  useEffect(() => {
    setLength(cardItems.length);
  }, [cardItems]);
  
  const prev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? length - 1 : prevIndex - 1));
  };

  const next = () => {
    setCurrentIndex((prevIndex) => (prevIndex === length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <section className="carousel">
      <div className="carousel__container">
        <div className="carousel__slide" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          <TransitionGroup className="carousel__slide-inner">
              {extendedCardItems.map((card, index) => (
                <CSSTransition key={index} timeout={300} classNames="slide-transition">
                  <div className="carousel__slide-item">
                    <Card 
                      key={card.id} 
                      properties={card}
                      setCartItemCount={setCartItemCount}/>
                  </div>
                </CSSTransition>
              ))}
            </TransitionGroup>
        </div>
      </div>
      <div className='carousel__buttons'>
        <button className="carousel__prev-button" onClick={prev} disabled={length === 0}>
          &#8249;
        </button>
        <button className="carousel__next-button" onClick={next} disabled={length === 0 || currentIndex === length - 1}>
          &#8250;
        </button>
      </div>
    </section>
  );
}

export default Carousel;


