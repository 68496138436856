import Hovered from "../Hovered/Hovered";
import { INPUT_ERRORS } from "../../utils/constants";
import { useFormWithValidation } from "../../hooks/useFormWithValidation";
import { useState } from "react";
import RequestError from "../RequestError/RequestError";

function ContactForm({onSubmitForm}) {

  const [submitButtonText, setSubmitButtonText] = useState("Отправить");
  const [requestError, setRequestError] = useState("");
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const { values, handleInputChange, errors, isValid } =
    useFormWithValidation();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsFormDisabled(true);
    setSubmitButtonText("Отправляем...");
    onSubmitForm(values)
      .then(() => {
        console.log(values, "form received response");
        setSubmitButtonText("Отправлено");
      })
      .catch((err) => {
        setRequestError(err);
        setIsFormDisabled(false);
        setSubmitButtonText("Отправить");
        console.log(err);
      });
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <label className="contact-form__label">
        Ваше Имя*:
        <input
          className="contact-form__input"
          placeholder="Иван Иванович Иванов"
          id="name"
          type="text"
          value={values.name || ""}
          pattern="^[a-zA-Zа-яёА-ЯЁ\s\-]+$"
          onChange={handleInputChange}
          name="name"
          minLength="2"
          maxLength="30"
          disabled={isFormDisabled}
          required
        ></input>
        <span className="contact-form__input-error">
          {errors.name ? INPUT_ERRORS.WRONG_NAME : ""}
        </span>
      </label>
      <label className="contact-form__label">
        Номер телефона*:
        <input
          className="contact-form__input"
          placeholder="+7 (999) 123-45-67"
          id="phone"
          type="tel"
          value={values.phone || ""}
          onChange={handleInputChange}
          name="phone"
          minLength="10"
          maxLength="18"
          disabled={isFormDisabled}
          required
        ></input>
        <span className="contact-form__input-error">
          {errors.phone ? INPUT_ERRORS.WRONG_PHONE : ""}
        </span>
      </label>
      <label className="contact-form__label">
        Ваш e-mail:
        <input
          className="contact-form__input"
          placeholder="name@gmail.com"
          id="email"
          type="email"
          value={values.email || ""}
          onChange={handleInputChange}
          name="email"
          minLength="5"
          maxLength="30"
          disabled={isFormDisabled}
        ></input>
        <span className="contact-form__input-error">
          {errors.email ? INPUT_ERRORS.WRONG_EMAIL : ""}
        </span>
      </label>
      <RequestError
        className="contact-form__request-error"
        errorText={requestError}
      />
      <Hovered>
        <button
          className={`contact-form__button${!isValid || isFormDisabled ? " contact-form__button_disabled" : ""}`}
        >
          {submitButtonText}
        </button>
      </Hovered>
    </form>
  );
}

export default ContactForm;
